import React from 'react';

import styled from 'styled-components';

import { PageLayout } from 'templates/2024/PageLayout';

import ExploreCurriculum from '../../components/our-principles/ExploreCurriculum';
import FocusCards from '../../components/our-principles/FocusCards';
import HeroSection from '../../components/our-principles/HeroSection';
import PrinciplesSection from '../../components/our-principles/PrinciplesSection';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: -5;
`;

const OurPrinciples: React.FC = () => {
  return (
    <PageLayout>
      <Container>
        {/* This page is imported from the below components */}
        <HeroSection />
        <FocusCards />
        <PrinciplesSection />
        <ExploreCurriculum
          title="Explore our core science curriculum"
          exploreCardsData={[
            {
              title: 'Completely Customisable',
              description:
                'Teach right away or curate your perfect lesson with our editable content.',
              url: '',
            },
            {
              title: 'About Us',
              description: 'Meet the experienced, multi-faceted team behind Stile.',
              url: '',
            },
            {
              title: 'Product Overview',
              description: 'Helping teachers implement evidence-based strategies.',
              url: '',
            },
          ]}
        />
      </Container>
    </PageLayout>
  );
};
export default OurPrinciples;
