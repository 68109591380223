import * as React from 'react';

import fingerprintBgMobile from 'stile-shared/static/fingerprint-bg-mobile.svg';
import fingerprintBg from 'stile-shared/static/fingerprint-bg.svg';
import styled from 'styled-components';

import { Testimonial } from '../../common/Testimonial';

import { Grid } from '../Grid';

interface Testimonial {
  name: string;
  title: string;
  quote: string;
}

type TestimonialsSectionProps = React.HTMLAttributes<HTMLDivElement> & {
  testimonials: Testimonial[];
  headingText: string | React.ReactNode;
};

const TestimonialsSectionStyledDiv = styled.div`
  padding: 80px 0;
  background: var(--stile-green-2) url('${fingerprintBg}') repeat top center;

  @media (max-width: 786px) {
    padding: 60px 0 40px 0;
    background: var(--stile-green-2) url('${fingerprintBgMobile}') repeat top center;
  }

  .content {
    .testimonial-1 {
      grid-column: 7 / span 6;
      grid-row: 1 / span 1;
      margin-bottom: 25px;
      @media (max-width: 786px) {
        grid-column: 1 / span 12;
        margin-bottom: 30px;
      }
    }

    .testimonial-2 {
      grid-column: 1 / span 6;
      grid-row: 2 / span 1;
      @media (max-width: 786px) {
        grid-column: 1 / span 12;
      }
    }

    .headingText {
      grid-column: 3 / span 8;
      grid-row: 3 / span 1;
      text-align: center;
      margin: 60px 0 90px 0;
      h2 {
        padding: 0;
        margin: 0;
      }
      @media (max-width: 786px) {
        grid-column: 1 / span 12;
        margin: 40px 0;
      }
    }

    .testimonial-3 {
      grid-column: 7 / span 6;
      grid-row: 4 / span 1;
      margin-bottom: 25px;
      @media (max-width: 786px) {
        grid-column: 1 / span 12;
        margin-bottom: 30px;
      }
    }

    .testimonial-4 {
      grid-column: 1 / span 6;
      grid-row: 5 / span 1;
      @media (max-width: 786px) {
        grid-column: 1 / span 12;
      }
    }
  }
`;

/**
 * Displays content in a simple TestimonialsSection.
 */
export const TestimonialsSection = ({
  testimonials,
  headingText,
  className,
  ...props
}: React.PropsWithChildren<TestimonialsSectionProps>) => {
  return (
    <TestimonialsSectionStyledDiv className={`${className}`} {...props}>
      <div className="content">
        <Grid>
          {testimonials
            .slice(0, 2)
            .map(
              (testimonial, index) =>
                testimonial && <Testimonial key={index} index={index + 1} {...testimonial} />
            )}
          <div className="headingText">
            <h2>{headingText}</h2>
          </div>
          {testimonials
            .slice(2, 4)
            .map(
              (testimonial, index) =>
                testimonial && <Testimonial key={index} index={index + 3} {...testimonial} />
            )}
        </Grid>
      </div>
    </TestimonialsSectionStyledDiv>
  );
};
