// WITBFeatureSection/ForwardArrow.tsx
import React from 'react';

interface ForwardArrowProps {
  className?: string;
}

const ForwardArrow: React.FC<ForwardArrowProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_1_232" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="2" y="3" width="16" height="14">
      <path d="M2 10.8125L14.8881 10.8125L10.3463 15.3544L11.5 16.5L18 10L11.5 3.5L10.3544 4.64562L14.8881 9.1875L2 9.1875L2 10.8125Z" fill="currentColor" />
    </mask>
    <g mask="url(#mask0_1_232)">
      <rect x="-10" y="-10" width="40" height="40" fill="currentColor" />
    </g>
  </svg>
);

export default ForwardArrow;
