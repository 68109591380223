import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';
import { inputStyle } from '../../../../styles/2020/typography';

import { Column } from '../../layout/Column';

type FormFieldProps = React.ComponentProps<typeof Column> & {};

export function FormField(props: React.PropsWithChildren<FormFieldProps>) {
  return <FormFieldContainer space={theme.space.xxs} {...props} />;
}

const FormFieldContainer = styled(Column)<FormFieldProps>`
  input,
  textarea {
    display: flex;
    flex: 1;
    width: 100%;
    ${inputStyle}
  }

  textarea {
    resize: none;
  }
`;
