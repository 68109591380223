import * as React from 'react';

import { ButtonLink } from '../../common/ButtonLink';
import { TrialButton } from '../../common/TrialButton';

export function LeftButton(props: React.ComponentProps<typeof TrialButton>) {
  return <TrialButton {...props} />;
}

export function RightButton(props: React.ComponentProps<typeof ButtonLink>) {
  return <ButtonLink variant="secondary" {...props} />;
}
