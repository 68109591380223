import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import plus from 'stile-shared/static/Plus.svg';
import downArrow from 'stile-shared/static/down-arrow.png';
import expertAssistanceImage from 'stile-shared/static/expert-assistance-large.jpg';
import underline1 from 'stile-shared/static/underline-1.png';
import underline2 from 'stile-shared/static/underline-2.png';
import underline3 from 'stile-shared/static/underline-3.png';
import underline4 from 'stile-shared/static/underline-4.png';
import underline5 from 'stile-shared/static/underline-5.png';
import underline6 from 'stile-shared/static/underline-6.png';
import underline7 from 'stile-shared/static/underline-7.png';
import underline8 from 'stile-shared/static/underline-8.png';
import underline9 from 'stile-shared/static/underline-9.png';
import underline10 from 'stile-shared/static/underline-10.png';
import underline11 from 'stile-shared/static/underline-11.png';
import styled from 'styled-components';

import iconPage from 'stile-shared/assets/icons/icon_page.svg';
import iconRightArrow from 'stile-shared/assets/icons/icon_right_arrow.svg';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import { Card } from 'stile-shared/src/components/2024/common/Card';
import { Icon } from 'stile-shared/src/components/2024/common/Icon';
import { OtherSlider } from 'stile-shared/src/components/2024/common/OtherSlider';
import { Slider } from 'stile-shared/src/components/2024/common/Slider';
import { Testimonial } from 'stile-shared/src/components/2024/common/Testimonial';
import { Grid } from 'stile-shared/src/components/2024/layout/Grid';
import { Section } from 'stile-shared/src/components/2024/layout/Section';
import { TestimonialsSection } from 'stile-shared/src/components/2024/layout/TestimonialsSection';
import { TopSection } from 'stile-shared/src/components/2024/layout/TopSection';

import { PageLayout } from 'templates/2024/PageLayout';

export { Head } from 'templates/2024/Head';

const testimonials = [
  {
    name: 'Eric Bardwell',
    title: 'Vale School District',
    quote:
      'The information was more than just a presentation.  Information was modeled, and time was given to practice.',
  },
  {
    name: 'Ashley Beard',
    title: 'Morrow County',
    quote:
      "Out of the many PD's that I've sat in over 16 years, this is one of the most beneficial PD's. It was applicable to teachers.",
  },
  {
    name: 'Kire Barrazaross',
    title: 'Hermiston School District',
    quote: 'High energy, informative & concise.',
  },
  {
    name: 'Megan Sheppard',
    title: 'Springfield School District',
    quote: 'Super informative, practical and easy to follow.',
  },
];

const StilePLTopSection = styled<typeof TopSection>(TopSection)`
  @media (max-width: 768px) {
    ul {
      padding-left: 25px;
    }
  }
`;

const QuoteReferenced = styled.div`
  grid-column: 4 / span 5;
  text-align: center;
  margin: 80px 0 140px 0;
  color: var(--stile-grey-6);
  @media (max-width: 768px) {
    grid-column: 1 / span 12;
    margin: 50px 0 50px 0;
    text-align: left;
  }
`;

const BasicTrainingSection = styled<typeof Section>(Section)`
  margin-top: 0 !important;
  grid-column: span 12;

  position: relative;
  /* height: 400vh; */

  h2 {
    @media (max-width: 768px) {
      margin-bottom: 20px !important;
    }
  }

  .--subheader {
    color: var(--stile-grey-8);
    grid-column: 4 / span 6;
    text-align: center;
    margin-bottom: 0px;
    @media (max-width: 768px) {
      grid-column: 1 / span 12;
      p {
        &:first-child {
          margin-top: 0;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .grid-layout {
    margin-top: 0 !important;
  }

  > .grid-layout {
    grid-column: span 12;
    align-items: center;
    margin-bottom: 150px;
    @media (max-width: 768px) {
      margin-bottom: 80px;
    }
  }
`;

const StileAcademySection = styled<typeof BasicTrainingSection>(BasicTrainingSection)`
  .--subheader {
    color: var(--white);
    grid-column: 3 / span 8;

    h4 {
      margin: 0 0 32px 0;
    }

    p {
      margin: 0;
    }

    @media (max-width: 768px) {
      grid-column: 1 / span 12;
    }
  }

  .--otherSlider {
    margin-top: 80px !important;
    margin-bottom: 150px !important;
    padding-bottom: 0 !important;
    .--tabs {
      padding-bottom: 0 !important;
      > div:last-child {
        margin-bottom: 0 !important;
      }
    }
  }

  .--second-section {
    grid-column: span 12;
    color: var(--stile-green-2);
    text-align: center;
    margin: 0 0 60px 0;
  }

  .slider {
    grid-column: 1 / span 12;
    margin-bottom: 150px;
  }
`;

const ProfessionalDevelopmentSection = styled.div`
  margin-top: 0 !important;
  grid-column: span 12;
  position: relative;

  .--subheader {
    color: var(--white);
    grid-column: 4 / span 6;
    text-align: center;
    margin-bottom: 32px;
    @media (max-width: 768px) {
      grid-column: 1 / span 12;
    }
  }

  > .grid-layout {
    margin-top: 56px;
    grid-column: span 12;
    align-items: center;
    margin-bottom: 150px;
    @media (max-width: 768px) {
      margin-bottom: 80px;
    }
  }

  .--progressor {
    grid-column: 1 / span 12;
    margin-top: 100px;
    padding-bottom: 190px;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    position: relative;
    @media (max-width: 768px) {
      margin-top: 0;
      padding-bottom: 80px;
    }

    .--tabs {
      position: relative;
      padding-left: 55px;
      padding-top: 80px;
      padding-bottom: 124px;
      grid-column: 1 / span 12;
      @media (max-width: 768px) {
        padding-top: 40px;
        padding-bottom: 0;
        padding-left: 0;
      }

      .--progressor-swirl {
        position: absolute;
        height: 300px;
        top: 130px;
        left: 0;
        img {
          height: 300px;
        }
        @media (max-width: 768px) {
          display: none;
        }
      }

      > div:not(.--progressor-swirl) {
        display: flex;
        position: relative;

        &:nth-child(2) {
          button {
            .--header {
            }
          }
        }

        &:last-child {
          button {
            .--bottom-border {
              flex-grow: 1;
              border-bottom: 1px solid var(--stile-grey-7);
              margin-left: 58px;
              @media (max-width: 768px) {
                margin-left: 44px;
              }
            }
          }
        }

        button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          text-align: left;
          color: var(--white);
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          flex-grow: 1;
          padding: 0;

          .--header {
            flex-grow: 1;
            align-content: end;
            margin-left: 20px;
            position: relative;
            padding-top: 30px;
            padding-bottom: 30px;
            border-top: 1px solid var(--stile-grey-7);
            display: flex;
            @media (max-width: 768px) {
              margin-left: 12px;
              font-size: 14px;
              line-height: 16px;
              .--expand-image {
                flex-grow: 1;
                display: inline-block;
                background: url('${plus}') no-repeat center right;
                height: 10px;
                width: 10px;
              }
            }
          }

          h6 {
            border-radius: 50%;
            background-color: var(--stile-grey-4);
            color: var(--white);
            width: 38px;
            height: 38px;
            line-height: 38px;
            text-align: center;
            margin: 0;
            z-index: 2;
            @media (max-width: 768px) {
              width: 32px;
              height: 32px;
              line-height: 32px;
            }
          }
          .--break {
            flex-basis: 100%;
            height: 0;
          }

          @media (max-width: 768px) {
            .--arrow-image {
              background: url('${downArrow}') no-repeat bottom center;
              position: absolute;
              top: 56px;
              bottom: -20px;
              left: 0;
              height: auto;
              width: 32px;
              z-index: 1;
            }
          }
        }

        &.--active {
          h6 {
            background-color: var(--stile-green-1);
          }
          .--header {
            color: var(--stile-green-1);
            .--expand-image {
              display: none;
            }
          }

          @media (max-width: 768px) {
            .--card {
              margin-left: 40px;
              margin-bottom: 20px;
              display: flex;
              padding: 25px 25px 22px 25px;
              font-size: 12px;
              line-height: 17px;
            }
          }
        }
      }
    }

    .--card {
      display: none;
      width: 388px;
      padding: 40px;
      border-radius: 32px;
      background-color: var(--white);
      border: 1px solid var(--stile-green-1);
      flex-direction: column;

      &.--active {
        display: flex;
      }

      @media (max-width: 768px) {
        width: auto;
        &.--active {
          display: none;
        }
      }

      p {
        margin: 0;
        padding: 20px 0 0 0;
        color: var(--stile-grey-8);
      }

      .gatsby-image-wrapper {
        &.--active {
          display: block;
        }
      }
    }

    .--cards {
      position: absolute;
      right: 100px;
      @media (max-width: 768px) {
        position: static;
        grid-column: 1 / span 12;
        grid-row: 1;
      }
    }
  }
`;

const PLTestimonialSection = styled<typeof TestimonialsSection>(TestimonialsSection)``;

const ExpertAssistance = styled.div`
  padding: 150px 0;
  @media (max-width: 768px) {
    padding: 0;
  }
  .content {
    background: url('${expertAssistanceImage}') no-repeat;
    background-size: cover;
    border-radius: 32px;
    overflow: hidden;
    box-shadow: 0px 660px 185px 0px rgba(0, 0, 0, 0), 0px 422px 169px 0px rgba(0, 0, 0, 0.01),
      0px 238px 143px 0px rgba(0, 0, 0, 0.03), 0px 106px 106px 0px rgba(0, 0, 0, 0.04),
      0px 26px 58px 0px rgba(0, 0, 0, 0.05);

    @media (max-width: 1000px) {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%),
        url('${expertAssistanceImage}') lightgray 50% 0% / 225% no-repeat;
    }

    @media (max-width: 768px) {
      margin: 0;
      border-radius: 0;
      background: none;
    }

    > div {
      height: 800px;
      padding: 70px;
      border-radius: 32px;
      border: 1px solid var(--stile-green-1);
      position: relative;
      overflow: hidden;

      @media (max-width: 768px) {
        height: auto;
        padding: 0;
        border-radius: 0%;
        border: none;
      }

      .--headings {
        display: flex;
        flex-direction: column;
        gap: 38px;
        width: 45%;
        @media (max-width: 768px) {
          gap: 0;
          width: calc(100%);
          padding: 450px 25px 40px 25px;
          background-position-x: 50%;
          background-position-y: 0%;
          background-size: 225%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 100%),
            url('${expertAssistanceImage}') lightgray 50% 0% / 225% no-repeat;
        }
        .h9 {
          margin: 0;
          padding: 0;
          @media (max-width: 1000px) {
            margin-bottom: 25px;
            color: var(--white);
          }
        }
        h4 {
          margin: 0;
          padding: 0;
          @media (max-width: 1000px) {
            color: var(--white);
          }
        }
      }
      .--copy {
        display: flex;
        flex-direction: column;
        gap: 32px;
        width: 45%;
        @media (max-width: 768px) {
          gap: 0;
          width: 100%;
          padding: 0px 25px 80px 25px;
        }
        p {
          width: 75%;

          @media (max-width: 1000px) {
            color: var(--white);
          }
          @media (max-width: 768px) {
            color: var(--stile-grey-8);
            width: 100%;
            /* margin-top: 50px; */
          }
        }
        .button {
          div {
            margin-left: 10px;
          }
        }
      }
    }
  }
`;

const StilePLLayout = styled<typeof PageLayout>(PageLayout)`
  /* @keyframes animatedReveal {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  } */
  .underline-hidden {
    :after {
      /* width: 0 !important; */
    }
  }

  .underline-animation {
    :after {
      /* animation: animatedReveal 1s; */
    }
  }

  .underline-1,
  .underline-2,
  .underline-3,
  .underline-4,
  .underline-5,
  .underline-6,
  .underline-7,
  .underline-8,
  .underline-9,
  .underline-10,
  .underline-11 {
    display: inline-block;
    position: relative;
    :after {
      content: '';
      display: block;
      position: absolute;
      bottom: -8px;
      width: 100%;
      height: 28px;
      background-repeat: no-repeat;
      background-position: top left;
      background-size: cover;
      border-top-right-radius: 14px;
      border-bottom-right-radius: 14px;
    }
  }

  .underline-1 {
    :after {
      background-image: url('${underline1}');
    }
  }

  .underline-2 {
    :after {
      background-image: url('${underline2}');
    }
  }

  .underline-3 {
    :after {
      background-image: url('${underline3}');
      @media (max-width: 768px) {
        bottom: -17px;
        background-size: 100%;
      }
    }
  }

  .underline-4 {
    :after {
      background-image: url('${underline4}');
      bottom: -20px;
      left: -20px;
      @media (max-width: 768px) {
        bottom: -15px;
        width: 100%;
        background-size: 100%;
      }
    }
  }

  .underline-5 {
    :after {
      background-image: url('${underline5}');
      height: 18px;
      bottom: -5px;
      @media (max-width: 768px) {
        bottom: -10px;
        width: 100%;
        background-size: 100%;
      }
    }
  }

  .underline-6 {
    :after {
      background-image: url('${underline6}');
      height: 18px;
      @media (max-width: 768px) {
        background-size: 100%;
      }
    }
  }

  .underline-7 {
    :after {
      background-image: url('${underline7}');
      height: 18px;
      bottom: 0px;
    }
  }

  .underline-8 {
    :after {
      background-image: url('${underline8}');
      height: 18px;
      bottom: 0px;
      @media (max-width: 768px) {
        bottom: -5px;
        background-size: 100%;
      }
    }
  }

  .underline-9 {
    :after {
      background-image: url('${underline9}');
      height: 18px;
      bottom: 0px;
      @media (max-width: 768px) {
        bottom: -10px;
        background-size: 100%;
      }
    }
  }

  .underline-10 {
    :after {
      background-image: url('${underline10}');
      height: 18px;
      bottom: 0px;
      @media (max-width: 768px) {
        bottom: -10px;
        background-size: 100%;
      }
    }
  }

  .underline-11 {
    :after {
      background-image: url('${underline11}');
      height: 18px;
      bottom: 0px;
      @media (max-width: 768px) {
        bottom: -5px;
        background-size: 100%;
      }
    }
  }
`;

const LearningOptionsSection = styled<typeof Section>(Section)`
  .content {
    padding-top: 100px !important;
  }
  .--subheader {
    color: var(--stile-grey-8);
    grid-column: 4 / span 6;
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 768px) {
      grid-column: 1 / span 12;
      margin-bottom: 40px;
    }
  }

  .grid-layout {
    margin-top: 0 !important;
  }

  .cards-container {
    grid-column: span 12;
    padding-bottom: 150px;

    @media (max-width: 768px) {
      padding-bottom: 80px;
    }

    .card {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const StileShowcaseSection = styled<typeof Section>(Section)`
  h2 {
    margin: 0 0 32px 0 !important;
  }

  .content {
    padding-top: 100px !important;
  }

  .--subheader {
    color: var(--stile-grey-8);
    grid-column: 4 / span 6;
    text-align: center;
    margin-bottom: 80px;
    @media (max-width: 768px) {
      grid-column: 1 / span 12;
      margin-bottom: 40px;
    }
  }

  .showcase-content {
    grid-column: span 12;
  }

  .grid-layout {
    margin-top: 0 !important;
    @media (max-width: 768px) {
      padding-bottom: 80px;
    }
  }

  .--otherSlider {
    margin-top: 0px !important;

    .--tabs {
      > div {
        h4,
        ul {
          color: var(--stile-grey-8) !important;
          opacity: 0.7;
          @media (max-width: 768px) {
            opacity: 1 !important;
          }
        }
        &:before {
          background-color: var(--stile-green-3) !important;
          opacity: 0.7;
        }
        &:after {
          background-color: var(--white) !important;
        }
        &.--active {
          h4,
          ul {
            opacity: 1;
            @media (max-width: 768px) {
              color: var(--stile-grey-8) !important;
            }
          }
        }
      }
    }

    .--images {
      padding: 0 !important;
      justify-items: end;
    }
  }
`;

type EvidenceCardProps = React.HTMLAttributes<HTMLDivElement> & {
  title: string;
  content: string;
  image: React.ReactNode;
  direction?: 'left' | 'right';
};

const EvidenceCard = function ({
  title,
  content,
  image,
  direction = 'left',
  ...props
}: EvidenceCardProps) {
  return (
    <div className={`--evidence-card --${direction}`} {...props}>
      <div className="--image --left">{image}</div>
      <h4>{title}</h4>
      <div className="--content">{content}</div>
      <div className="--image --right">{image}</div>
    </div>
  );
};

const StyledEvidenceCard = styled<typeof EvidenceCard>(EvidenceCard)`
  grid-column: 3 / span 8;
  display: grid;
  padding: 0 0 80px 0;
  grid-template-columns: ${({ direction }) => (direction === 'left' ? '175px auto' : 'auto 175px')};
  grid-template-rows: min-content min-content;

  @media (max-width: 768px) {
    grid-column: 1 / span 12;
    padding: 0 0 40px 0;
    /* flex-direction: row-reverse; */
    grid-template-columns: 52px auto;
    grid-template-rows: min-content min-content;
  }

  h4 {
    padding: ${({ direction }) => (direction === 'left' ? '0 0 25px 28px' : '0 28px 25px 0')};
    margin: 0;
    height: min-content;
    grid-column: ${({ direction }) => (direction === 'left' ? '2 / span 1' : '1 / span 1')};

    @media (max-width: 768px) {
      height: 52px;
      align-content: center;
      grid-column: 2 / span 1;
      padding: 0 0 0 12px;
    }
  }

  .--content {
    grid-column: ${({ direction }) => (direction === 'left' ? '2 / span 1' : '1 / span 1')};
    height: min-content;
    padding: ${({ direction }) => (direction === 'left' ? '0 0 0 28px' : '0 28px 0 0')};

    @media (max-width: 768px) {
      grid-column: 1 / span 2;
      grid-row: 2;
      padding: 20px 0 0 0;
    }
  }

  .--image {
    width: 175px;
    height: 175px;
    .gatsby-image-wrapper {
      img {
        height: auto !important;
      }
    }
    grid-row: 1 / span 2;
    @media (max-width: 768px) {
      height: 52px;
      width: 52px;
      align-content: center;
      grid-column: 1 / span 1;
    }
    align-content: center;

    &.--left {
      display: ${({ direction }) => (direction === 'left' ? 'flex' : 'none')};
      grid-column: 1 / span 1;
      @media (max-width: 768px) {
        grid-column: 1 / span 1;
        display: flex;
      }
    }

    &.--right {
      display: ${({ direction }) => (direction === 'right' ? 'flex' : 'none')};
      grid-column: 2 / span 1;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
`;

const EvidenceBasedSection = styled<typeof Section>(Section)`
  padding-bottom: 70px;
  @media (max-width: 768px) {
    padding-bottom: 0;
  }

  .grid-layout {
    margin-top: 0 !important;
  }

  h2 {
    margin: 0 0 110px 0 !important;

    @media (max-width: 768px) {
      margin-bottom: 60px !important;
    }
  }
`;

function TwentyTwentyFourPLPage(props: PageProps) {
  const [progressorIndex, setProgressorIndex] = React.useState(0);

  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const underline = entry.target;

          if (entry.isIntersecting) {
            setTimeout(() => {
              underline.classList.remove('underline-hidden');
              underline.classList.add('underline-animation');
            }, 500);
            return; // if we added the class, exit the function
          }

          // We're not intersecting, so remove the class!
          underline.classList.add('underline-hidden');
          underline.classList.remove('underline-animation');
        });
      },
      {
        threshold: 0.9,
      }
    );

    const numberOfUnderlines = 11;
    for (let i = 1; i <= numberOfUnderlines; i++) {
      const underline = document.querySelector(`.underline-${i}`);
      // console.log('underline', underline);
      if (underline) observer.observe(underline);
    }
  }, []);

  return (
    <StilePLLayout {...props}>
      <StilePLTopSection
        headingContent="Professional Learning, done differently."
        subHeadingContent={
          <ul>
            <li>Evidence-based pedagogies, no fads.</li>
            <li>Master the basics of Stile at your own pace.</li>
            <li>Stile Academy: 15 modules, one-on-one coaching, micro-certifications.</li>
          </ul>
        }
        coverImage={
          <StaticImage
            src="../../../../stile-shared/assets/images/stile-pl/cover_stile_pl_2024.png"
            alt="cover image"
            placeholder="none"
          />
        }
      />

      <Section
        backgroundColor="--white"
        subHeadingColor="--stile-green-2"
        subHeadingContent="Did you know?"
        className="hide-mobile"
        headingContent={
          <span>
            Over <span className="underline-2">50% of the benefits</span> of switching to a
            comprehensive curriculum resource are <span className="underline-3">lost</span> if
            teachers don{`’`}t get proper training and support.<sup>*</sup>
            <br />
            <br />
            That{`’`}s a big deal. <br />
            But here{`’`}s <span className="underline-7">the good news</span>: <br />
            Stile{`’`}s Professional Learning is here to help.
            <br />
            <br />
            With 10 years of experience and countless hours working with thousands of teachers, we
            offer practical, hands-on, evidence-based programs that deliver{' '}
            <span className="underline-4">real results</span>.
          </span>
        }
      >
        <QuoteReferenced className="--small">
          *Steiner, D., Magee, J., & Jensen, B. (2018). What we teach matters: How quality
          curriculum improves student outcomes. Johns Hopkins.
        </QuoteReferenced>
      </Section>

      <Section
        backgroundColor="--white"
        subHeadingColor="--stile-green-2"
        subHeadingContent="Did you know?"
        className="hide-desktop"
        headingContent={
          <span>
            Over <span className="underline-9">50%</span> of the{` `}
            <span className="underline-10">benefits</span> of switching to a comprehensive
            curriculum resource are <span className="underline-3">lost</span> if teachers don{`’`}t
            get proper training and support.<sup>*</sup>
            <br />
            <br />
            That{`’`}s a big deal. <br />
            But here{`’`}s the <span className="underline-11">good</span> news: Stile{`’`}s
            Professional Learning is here to help.
            <br />
            <br />
            With 10 years of experience and countless hours working with thousands of teachers, we
            offer practical, hands-on, evidence-based programs that deliver{' '}
            <span className="underline-4">real results</span>.
          </span>
        }
      >
        <QuoteReferenced className="--small">
          *Steiner, D., Magee, J., & Jensen, B. (2018). What we teach matters: <br /> How quality
          curriculum improves student outcomes. Johns Hopkins.
        </QuoteReferenced>
      </Section>

      <LearningOptionsSection
        backgroundColor="--stile-green-2"
        headingColor="--stile-grey-8"
        headingContent={
          <span>
            Learning options,
            <br />
            designed for you
          </span>
        }
        textColor="--stile-grey-8"
      >
        <Grid className="cards-container">
          <Card
            cardTitle={
              <span>
                Self-paced <br /> basics training
              </span>
            }
            callOuts={[
              'On-demand videos',
              'Learn at your own pace',
              'Included with every subscription',
            ]}
            headerImage={
              <StaticImage
                src="../../../../stile-shared/assets/images/stile-pl/card-1.png"
                alt="Self-paced basics training"
              />
            }
          />

          <Card
            cardTitle={
              <span>
                Stile
                <br />
                Academy
              </span>
            }
            callOuts={[
              'Self-paced micro-certification courses',
              'One-on-one coaching from a leading teacher',
              'Evidence-based pedagogies. No fads.',
            ]}
            headerImage={
              <StaticImage
                src="../../../../stile-shared/assets/images/stile-pl/card-3.png"
                alt="Stile Academy"
              />
            }
          />

          <Card
            cardTitle={
              <span>
                Workshops
                <br />
                &amp; events
              </span>
            }
            callOuts={[
              'In-depth and hands-on learning',
              'At-school workshops mean we come to you',
              "Tailored to your school's needs",
            ]}
            headerImage={
              <StaticImage
                src="../../../../stile-shared/assets/images/stile-pl/card-2.png"
                alt="Workshops & events"
              />
            }
          />
        </Grid>
      </LearningOptionsSection>

      <BasicTrainingSection
        headingColor="--stile-green-3"
        headingContent={
          <span>
            Basics training:
            <br />
            every teacher flying with Stile
          </span>
        }
        backgroundColor="--stile-grey-1"
      >
        <div className="--subheader">
          You don&apos;t want to have to attend in-person training to start using a new curriculum.
          That&apos;s why we&apos;ve built everything into Stile itself. The best part? Stile
          automatically detects what you have and haven&apos;t done, and prompts you to complete a
          full on-boarding at your own pace.
        </div>

        <OtherSlider
          titles={['Self-paced training']}
          callOuts={[
            [
              'On-demand videos',
              'Learn at your own pace',
              'Stile detects what you have and haven’t done',
              'Earn badges for each module',
              'Included with every subscription',
            ],
          ]}
          images={[
            <StaticImage
              key={0}
              src="../../../../stile-shared/assets/images/stile-pl/integrating-stile-1.png"
              alt="Self-paced training"
            />,
          ]}
        />
      </BasicTrainingSection>

      <StileAcademySection
        backgroundColor="--stile-grey-8"
        headingColor="--stile-green-2"
        headingContent="Stile Academy"
        textColor="--white"
      >
        <div className="--subheader">
          <h4>A new model for Professional Learning</h4>
          <p>
            Stile Academy is a targeted, continuous PL program for individual teachers centred
            around evidence-based strategies and learning theory, specific to their needs.
          </p>
        </div>

        <OtherSlider
          color="--white"
          titles={['Stile Learning Partner', 'Micro-credentials']}
          callOuts={[
            [
              'Real-time support from a Stile Learning Partner',
              'Collaborative forums and peer feedback',
              'Optional coaching and virtual drop-ins',
            ],
            [
              <span key={0}>
                Earn micro-certifications for each of the <strong>15 interactive modules</strong>{' '}
                completed
              </span>,
              'Recognised by professional teaching standards',
              'Digital certificates with PL hours for your email signature',
            ],
          ]}
          images={[
            <StaticImage
              key={0}
              src="../../../../stile-shared/assets/images/stile-pl/stile-academy-1.png"
              alt="Stile Learning Partner"
            />,
            <StaticImage
              key={1}
              src="../../../../stile-shared/assets/images/stile-pl/stile-academy-2.png"
              alt="Micro-credentials"
            />,
          ]}
        />

        <h4 className="--second-section">Take a look inside some Stile Academy Modules</h4>

        <Slider
          tabs={[
            'Differentiation',
            'Tracking Student Progress',
            'Effective Feedback',
            'Socratic Seminars',
          ]}
          headings={[
            'Elevate learning with targeted differentiation',
            'Track progress and celebrate success',
            'Fuel growth with actionable feedback',
            'Spark critical thinking with Socratic dialogue',
          ]}
          paragraphs={[
            'Unlock the power of differentiation to meet each student’s unique needs. This module provides the tools to tailor your teaching, create a classroom where every learner feels supported, and spark those ‘aha moments’ when everything just clicks.',
            'Perfect planning, collecting, and analysing assessments to elevate your teaching. Spot trends, catch misconceptions, and make decisions that drive student success with progress checks to keep every student on track.',
            "Nail timely, specific, and actionable feedback with tools that offer real-time insights and live analytics. Mastering the art of feedback deepens students' understanding and confidence, propelling them to their full potential.",
            'Kickstart critical thinking with Socratic Seminars, sparking lively dialogue in the classroom. This module covers best practices for leading seminars and fostering meaningful discussions, helping students explore complex ideas and build on each other’s insights.',
          ]}
          whatYouWillLearn={[
            [
              'Assess and understand student needs',
              'Implement differentiated strategies effectively',
              'Enhance engagement and achievement',
            ],
            [
              'Analyse data for trends',
              'Inform decisions with data',
              'Monitor progress continuously',
            ],
            [
              'Provide timely, constructive feedback',
              'Use technology for real-time feedback',
              'Improve teaching with feedback',
            ],
            [
              'Facilitate and guide dialogue',
              'Promote critical thinking skills',
              'Assess student participation effectively',
            ],
          ]}
          images={[
            <StaticImage
              key={0}
              src="../../../../stile-shared/assets/images/stile-pl/pl-slider-1.png"
              alt="feedback"
            />,
            <StaticImage
              key={1}
              src="../../../../stile-shared/assets/images/stile-pl/pl-slider-2.png"
              alt="feedback"
            />,
            <StaticImage
              key={2}
              src="../../../../stile-shared/assets/images/stile-pl/pl-slider-3.png"
              alt="feedback"
            />,
            <StaticImage
              key={3}
              src="../../../../stile-shared/assets/images/stile-pl/pl-slider-4.png"
              alt="feedback"
            />,
          ]}
        />
      </StileAcademySection>

      <StileShowcaseSection
        backgroundColor="--stile-green-2"
        headingColor="--stile-grey-8"
        headingContent="Workshops and events"
        textColor="--stile-grey-8"
      >
        <div className="--subheader">
          Of course, sometimes you want to learn with your colleagues supported by a real, live
          expert in the flesh. Stile&apos;s in-school workshops provide personalized teacher
          training and professional learning focussed on the unique needs of your school.
        </div>
        <div className="showcase-content">
          <OtherSlider
            color="--white"
            titles={['Launch', 'Demo days', 'Professional Learning']}
            callOuts={[
              [
                'Full-day workshop',
                'In-person or virtual',
                'Everything you need to get started with Stile',
              ],
              [
                'A Stile teacher teaches your class',
                'See how to teach with Stile first-hand',
                'Get one-on-one coaching throughout the day',
              ],
              [
                'Half and full-day workshops',
                'In-person or virtual',
                'Deep dive into evidence-based teaching strategies',
                'Tailored to your school',
              ],
            ]}
            images={[
              <StaticImage
                key={0}
                src="../../../../stile-shared/assets/images/stile-pl/workshops-and-events-1.jpeg"
                alt="Launch"
              />,
              <StaticImage
                key={1}
                src="../../../../stile-shared/assets/images/stile-pl/workshops-and-events-2.jpeg"
                alt="Demo days"
              />,
              <StaticImage
                key={2}
                src="../../../../stile-shared/assets/images/stile-pl/workshops-and-events-3.jpeg"
                alt="Professional Learning"
              />,
            ]}
          />
        </div>
      </StileShowcaseSection>

      <EvidenceBasedSection
        backgroundColor="--white"
        headingColor="--stile-green-3"
        headingContent="An evidence-based approach to improve teacher practice"
        textColor="--stile-grey-8"
      >
        <StyledEvidenceCard
          direction="right"
          title="Build skills over time"
          content="No ‘one and done’ sessions here. With our evidence-based approach, teachers continuously develop skills and practices throughout their entire Stile journey. Each phase builds on the previous one, ensuring comprehensive support for teachers — and a lasting impact on students."
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-pl/evidence_success criteria 2.png"
              alt="Evidence-based strategies"
            />
          }
        />
        <StyledEvidenceCard
          direction="left"
          title="Easily apply what you’ve learnt"
          content="We focus on what really works in the classroom. Our Professional Learning sessions provide teachers with strategies, templates, and routines that are immediately applicable. No more theory without practice — everything we teach can be put to use the very next day."
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-pl/teachersaying_feedback 3.png"
              alt="Evidence-based strategies"
            />
          }
        />
        <StyledEvidenceCard
          direction="right"
          title="Tailored for your success"
          content="Stile’s Professional Learning is not one-size-fits-all. It’s designed to support your school’s strategic priorities and the unique needs of individual teachers. By focusing on what matters most to each teacher and their students, we help create a more effective and engaging learning environment."
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-pl/evidence_success criteria 1.png"
              alt="Evidence-based strategies"
            />
          }
        />
        <StyledEvidenceCard
          direction="left"
          title="Hands-on collaboration"
          content="We take Professional Learning into the heart of your teaching environment. By working directly in classrooms, we provide hands-on support and collaboration with teachers. This approach ensures that new strategies are seamlessly integrated into daily practice."
          image={
            <StaticImage
              src="../../../../stile-shared/assets/images/stile-pl/teachersaying_feedback 2.png"
              alt="Evidence-based strategies"
            />
          }
        />
      </EvidenceBasedSection>

      <div>
        <div style={{ height: 40, backgroundColor: 'var(--stile-green-3)' }}></div>

        <PLTestimonialSection
          testimonials={testimonials}
          headingText={
            <span>
              <span style={{ color: 'var(--white)' }} className="underline-8">
                98% of teachers
              </span>{' '}
              would recommend our professional learning to a colleague. But don&apos;t just take our
              word for it.
            </span>
          }
        />

        <div style={{ height: 40, backgroundColor: 'var(--stile-green-3)' }}></div>
      </div>

      <ExpertAssistance>
        <div className="content">
          <div>
            <div>
              <div className="--headings">
                <h4>Our in-house team of science teachers are at your service</h4>
              </div>
              <div className="--copy">
                <p>
                  We&apos;re here to guide you every step of the way, from planning to
                  implementation and beyond.
                </p>
                <Button
                  href="/what-is-stile/concierge/"
                  label={
                    <>
                      <span>Learn more about Stile Concierge</span>
                      <Icon src={iconRightArrow} iconSize="fontSize" />
                    </>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </ExpertAssistance>
      {/* 
      <Section
        backgroundColor="--stile-grey-8"
        headingColor="--white"
        headingContent="Stile Academy, a new model for Professional Learning "
      >
        <ProfessionalDevelopmentSection className="grid-layout">
          <div className="--subheader">
            <p>
              Stile Academy is a targeted, continuous PL program for individual teachers centred
              around evidence-based strategies and learning theory, specific to their needs.
            </p>
            <p>
              15 interactive online modules are delivered via the Stile platform and guided by a
              (real-life!) Stile Learning Partner, who supports teachers in completing each module
              with feedback, as well as optional virtual drop-in sessions or additional coaching
              opportunities. Teachers are then awarded a micro-certification for each module.
            </p>
          </div>
          <div className="--progressor">
            <div className="--tabs">
              <StaticImage
                className="--progressor-swirl"
                alt="swirling arrow"
                src="../../../../stile-shared/assets/images/stile-pl/progressor-swirl.png"
              />
              <div className={progressorIndex === 0 ? '--active' : ''}>
                <button onClick={() => setProgressorIndex(0)}>
                  <h6>S</h6>
                  <div className="--header">
                    Set the scene
                    <div className="--expand-image"></div>
                  </div>
                  <div className={`--break`}></div>
                  <div className={`--card`}>
                    <StaticImage
                      alt="Training resources"
                      src="../../../../stile-shared/assets/images/stile-pl/pl-progress-1.png"
                    />
                    <p>
                      Teachers receive four key resources: video explainers, expert interviews,
                      practice illustrations, and research summaries.
                    </p>
                  </div>
                  <div className="--arrow-image"></div>
                </button>
              </div>
              <div className={progressorIndex === 1 ? '--active' : ''}>
                <button onClick={() => setProgressorIndex(1)}>
                  <h6>T</h6>
                  <div className="--header">
                    Trajectory mapping
                    <div className="--expand-image"></div>
                  </div>
                  <div className={`--break`}></div>
                  <div className={`--card`}>
                    <StaticImage
                      alt="Training resources"
                      src="../../../../stile-shared/assets/images/stile-pl/pl-progress-2.png"
                    />
                    <p>
                      Dive into teaching methods, pinpoint areas for improvement, and set actionable
                      goals using evidence-based practices.
                    </p>
                  </div>
                  <div className="--arrow-image"></div>
                </button>
              </div>
              <div className={progressorIndex === 2 ? '--active' : ''}>
                <button onClick={() => setProgressorIndex(2)}>
                  <h6>I</h6>
                  <div className="--header">
                    Implement strategies
                    <div className="--expand-image"></div>
                  </div>
                  <div className={`--break`}></div>
                  <div className={`--card`}>
                    <StaticImage
                      alt="Training resources"
                      src="../../../../stile-shared/assets/images/stile-pl/pl-progress-3.png"
                    />
                    <p>
                      Apply learned concepts in real classroom settings. Record sessions or create
                      portfolios, capturing teaching methods, strategies, and student interactions.
                    </p>
                  </div>
                  <div className="--arrow-image"></div>
                </button>
              </div>
              <div className={progressorIndex === 3 ? '--active' : ''}>
                <button onClick={() => setProgressorIndex(3)}>
                  <h6>L</h6>
                  <div className="--header">
                    Leverage learning through feedback
                    <div className="--expand-image"></div>
                  </div>
                  <div className={`--break`}></div>
                  <div className={`--card`}>
                    <StaticImage
                      alt="Training resources"
                      src="../../../../stile-shared/assets/images/stile-pl/pl-progress-4.png"
                    />
                    <p>
                      Engage in peer review, exchanging constructive feedback on recorded teaching
                      sessions with fellow educators.
                    </p>
                  </div>
                  <div className="--arrow-image"></div>
                </button>
              </div>
              <div className={progressorIndex === 4 ? '--active' : ''}>
                <button onClick={() => setProgressorIndex(4)}>
                  <h6>E</h6>
                  <div className="--header">
                    Evaluate and enhance
                    <div className="--expand-image"></div>
                  </div>
                  <div className={`--break`}></div>
                  <div className={`--card`}>
                    <StaticImage
                      alt="Training resources"
                      src="../../../../stile-shared/assets/images/stile-pl/pl-progress-5.png"
                    />
                    <p>
                      Reflect on learnings, set specific, measurable goals for future development in
                      this area, and provide evidence of impact.
                    </p>
                  </div>
                  <div className="--bottom-border"></div>
                </button>
              </div>
            </div>
            <div className="--cards">
              <div className={`--card ${progressorIndex === 0 ? '--active' : ''}`}>
                <StaticImage
                  alt="Training resources"
                  src="../../../../stile-shared/assets/images/stile-pl/pl-progress-1.png"
                />
                <p>
                  Teachers receive four key resources: video explainers, expert interviews, practice
                  illustrations, and research summaries.
                </p>
              </div>
              <div className={`--card ${progressorIndex === 1 ? '--active' : ''}`}>
                <StaticImage
                  alt="Training resources"
                  src="../../../../stile-shared/assets/images/stile-pl/pl-progress-2.png"
                />
                <p>
                  Dive into teaching methods, pinpoint areas for improvement, and set actionable
                  goals using evidence-based practices.
                </p>
              </div>
              <div className={`--card ${progressorIndex === 2 ? '--active' : ''}`}>
                <StaticImage
                  alt="Training resources"
                  src="../../../../stile-shared/assets/images/stile-pl/pl-progress-3.png"
                />
                <p>
                  Apply learned concepts in real classroom settings. Record sessions or create
                  portfolios, capturing teaching methods, strategies, and student interactions.
                </p>
              </div>
              <div className={`--card ${progressorIndex === 3 ? '--active' : ''}`}>
                <StaticImage
                  alt="Training resources"
                  src="../../../../stile-shared/assets/images/stile-pl/pl-progress-4.png"
                />
                <p>
                  Engage in peer review, exchanging constructive feedback on recorded teaching
                  sessions with fellow educators.
                </p>
              </div>
              <div className={`--card ${progressorIndex === 4 ? '--active' : ''}`}>
                <StaticImage
                  alt="Training resources"
                  src="../../../../stile-shared/assets/images/stile-pl/pl-progress-5.png"
                />
                <p>
                  Reflect on learnings, set specific, measurable goals for future development in
                  this area, and provide evidence of impact.
                </p>
              </div>
            </div>
          </div>
        </ProfessionalDevelopmentSection>
      </Section> */}
    </StilePLLayout>
  );
}

export default TwentyTwentyFourPLPage;
