import * as React from 'react';

import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import {
  greyLink,
  hoverLink,
  inheritLink,
  inlineLink,
  unstyledLink,
} from '../../../../styles/2020/typography';

type LinkVariant = 'hover' | 'inline' | 'inherit' | 'unstyled' | 'grey';

type LinkProps = Omit<GatsbyLinkProps<undefined>, 'to' | 'state' | 'ref'> & {
  /* Internal urls should match: `/some/page/` – external can be anything */
  url: string;
  /* `inherit` to adopt parent colors, otherwise specify a variant to enforce style */
  variant?: LinkVariant;
  /* Open link in a new browser tab */
  openInNewTab?: boolean;
};

/**
 * NOTE: for some reason, calling `styled(Link)` can break dev build,
 * though without affecting prod build...
 * Recommend using <StyledItem as={Link} /> rather than StyledItem = styled(Link)
 * and using `&& {}` inside styles.
 * See: HeaderLogo as an example.
 */
export function Link({
  url,
  activeClassName,
  activeStyle,
  partiallyActive,
  replace,
  openInNewTab,
  variant = 'inline',
  children,
  ...props
}: LinkProps) {
  // Assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(url);

  if (internal) {
    return (
      <InternalLink
        to={url}
        activeClassName={activeClassName}
        activeStyle={activeStyle}
        partiallyActive={partiallyActive}
        replace={replace}
        $variant={variant}
        {...props}
      >
        {children}
      </InternalLink>
    );
  }
  return (
    <ExternalLink
      href={url}
      $variant={variant}
      {...(openInNewTab && { target: '_blank' })}
      {...props}
    >
      {children}
    </ExternalLink>
  );
}

const linkStyles: Record<LinkVariant, FlattenSimpleInterpolation> = {
  hover: hoverLink,
  inline: inlineLink,
  inherit: inheritLink,
  unstyled: unstyledLink,
  grey: greyLink,
};

type StyledLinkProps = { $variant: LinkProps['variant'] };

const InternalLink = styled(GatsbyLink)`
  ${({ $variant }: StyledLinkProps) => $variant && linkStyles[$variant]}
`;

const ExternalLink = styled.a`
  ${({ $variant }: StyledLinkProps) => $variant && linkStyles[$variant]}
`;
