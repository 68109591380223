import { css } from 'styled-components';

/* NOTE: These declrations are for gatsby apps consuming stile-shared.
  You must edit .storybook/preview-head.html and put fonts in /static/fonts/ *in each repo*
  to modify the fonts rendered in stile-shared Storybook.
  Keep changes to those and these in sync!
*/
export const typography = css`
  body {
    color: var(--stile-grey-8);
    font-family: Graphik;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    @media (max-width: 768px) {
      font-size: 16px;
      line-height: 24px;
    }

    .--small {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
  h1 {
    font-family: Boing;
    font-size: 60px;
    font-weight: 500;
    line-height: 65px;

    @media (max-width: 768px) {
      font-size: 38px;
      line-height: 39px;
    }
  }

  h2 {
    font-family: Boing;
    font-size: 55px;
    font-weight: 500;
    line-height: 60px;

    @media (max-width: 768px) {
      font-size: 34px;
      line-height: 35px;
    }
  }

  h3 {
    font-family: Boing;
    font-size: 50px;
    font-weight: 500;
    line-height: 55px;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 35px;
    }
  }

  h4 {
    font-family: Boing;
    font-size: 40px;
    font-weight: 500;
    line-height: 45px;

    @media (max-width: 768px) {
      font-size: 26px;
      line-height: 28px;
    }
  }

  h5 {
    font-size: 30px;
    font-weight: 600;
    line-height: 38px;

    @media (max-width: 768px) {
      font-size: 22px;
      line-height: 26px;
    }
  }

  h6 {
    font-size: 21px;
    font-weight: 600;
    line-height: 26px;

    @media (max-width: 768px) {
      font-size: 19px;
      line-height: 24px;
    }

    &.h7 {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;

      @media (max-width: 768px) {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &.h8 {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;

      @media (max-width: 768px) {
        line-height: 18px;
      }
    }

    &.h9 {
      font-size: 18px;
      font-weight: 500;
      line-height: 15px;

      text-transform: uppercase;

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  a {
    text-decoration: underline;
    color: var(--stile-grey-8);
  }

  .feature {
    font-size: 30px;
    font-weight: 400;
    line-height: 39px;
  }

  .quote {
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 24px;
    &.--small {
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
    }
  }
`;
