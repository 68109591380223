import * as React from 'react';

type HeaderMenuAction = SetMobileMenuActiveAction | SetActiveMenuDropdownAction;

type SetMobileMenuActiveAction = {
  type: 'setMobileMenuActive';
  payload: HeaderMenuState['mobileMenuActive'];
};

type SetActiveMenuDropdownAction = {
  type: 'setActiveMenuDropdown';
  payload: HeaderMenuState['activeMenuDropdown'];
};

type HeaderMenuDispatch = (action: HeaderMenuAction) => void;
export type HeaderMenuState = {
  mobileMenuActive: boolean;
  activeMenuDropdown: number | null;
  linkSections: HeaderLinkSection[];
};

export type HeaderLinkSection = {
  title: string;
  links: { text: string; url: string }[];
};

const HeaderMenuStateContext = React.createContext<
  { state: HeaderMenuState; dispatch: HeaderMenuDispatch } | undefined
>(undefined);

function headerMenuReducer(state: HeaderMenuState, action: HeaderMenuAction) {
  switch (action.type) {
    case 'setMobileMenuActive': {
      return { ...state, mobileMenuActive: action.payload };
    }
    case 'setActiveMenuDropdown': {
      return { ...state, activeMenuDropdown: action.payload };
    }
    default:
      return state;
  }
}

type HeaderMenuProviderProps = { linkSections: HeaderLinkSection[]; children: React.ReactNode };

function HeaderMenuProvider({ linkSections, children }: HeaderMenuProviderProps) {
  const [state, dispatch] = React.useReducer(headerMenuReducer, {
    mobileMenuActive: false,
    activeMenuDropdown: null,
    linkSections,
  });

  const value = { state, dispatch };
  return (
    <HeaderMenuStateContext.Provider value={value}>{children}</HeaderMenuStateContext.Provider>
  );
}

function useHeaderMenu() {
  const context = React.useContext(HeaderMenuStateContext);
  if (context === undefined) {
    throw new Error('useHeaderMenu must be used within a HeaderMenuProvider');
  }
  return context;
}

export { HeaderMenuProvider, useHeaderMenu };
