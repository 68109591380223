import React from 'react';

import WITBStatsGrid from '../WITBStatsGrid/WITBStatsGrid';
import WITBUnderlinedText from '../WITBUnderlinedText/WITBUnderlinedText';
import { StyledWITBStatsSection } from './WITBStatsSection.styles';

interface WITBStatsSectionProps {
  title: string;
  stats: { value: string; label: string }[];
}

const WITBStatsSection: React.FC<WITBStatsSectionProps> = ({ title, stats }) => (
  <StyledWITBStatsSection>
    <div className="content">
      <h4>
        <WITBUnderlinedText>Your</WITBUnderlinedText> core curriculum, ready to go
      </h4>
      <WITBStatsGrid stats={stats} />
    </div>
  </StyledWITBStatsSection>
);

export default WITBStatsSection;
