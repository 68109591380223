import * as React from 'react';

type TextAreaProps = {
  name: string;
  label: string;
  placeholder?: HTMLTextAreaElement['placeholder'];
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ name, label, placeholder, ...props }, ref) => (
    <>
      <label htmlFor={name}>{label}</label>
      <textarea id={name} name={name} placeholder={placeholder} {...props} ref={ref} />
    </>
  )
);

TextArea.displayName = 'TextArea';
