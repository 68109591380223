import React, { useEffect, useRef, useState } from 'react';

import ScrollIndicator from '../ScrollIndicator/ScrollIndicator';
import {
  ScrollIndicatorContainerWrapper,
  StyledWITBGridContainer,
} from './ScrollIndicatorContainer.styles';

interface ScrollIndicatorContainerProps {
  children: React.ReactNode;
}

const ScrollIndicatorContainer: React.FC<ScrollIndicatorContainerProps> = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;

    const gridContainer = containerRef.current;
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const containerScrollLeft = gridContainer.scrollLeft;
          const containerWidth = gridContainer.clientWidth;
          const newIndex = Math.round(containerScrollLeft / containerWidth);
          setCurrentIndex(newIndex);
          ticking = false;
        });

        ticking = true;
      }
    };

    gridContainer.addEventListener('scroll', handleScroll);

    return () => {
      gridContainer.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const totalCount = React.Children.count(children);

  return (
    <ScrollIndicatorContainerWrapper>
      <StyledWITBGridContainer ref={containerRef}>{children}</StyledWITBGridContainer>

      {totalCount > 1 && <ScrollIndicator currentIndex={currentIndex} totalCount={totalCount} />}

      {/* <div className="end-overlay"></div> */}
    </ScrollIndicatorContainerWrapper>
  );
};

export default ScrollIndicatorContainer;
