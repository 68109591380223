import '@reach/checkbox/styles.css';

import React, { ChangeEvent, ChangeEventHandler } from 'react';

import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox';
import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';
import { inputStyle } from '../../../../styles/2020/typography';

import { Icon } from '../../layout/Icon';
import { Row } from '../../layout/Row';

type CheckboxProps = {
  /* Text to display */
  label: string;
  /* Is this checked? */
  checked?: boolean;
  /* Fires when value changes */
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

/**
 * A checkbox that displays with a `label` and handles its own state.
 * Can control by passing `checked` and `onChange`.
 */
export const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, checked, onChange, ...props }, ref) => {
    const [checkedState, setChecked] = React.useState(checked || false);
    const localChecked = checked != null ? checked : checkedState;

    return (
      <Row as="label" justify="flex-start" wrap={false} space="0">
        <StyledCustomCheckboxContainer
          checked={checked != null ? checked : localChecked}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setChecked(event.target.checked);
            onChange && onChange(event);
          }}
        >
          <CustomCheckboxInput {...props} ref={ref} />
          <StyledIcon iconId="check" iconColor="brand" aria-hidden />
        </StyledCustomCheckboxContainer>
        {label}
      </Row>
    );
  }
);

Checkbox.displayName = 'Checkbox';

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
`;

const StyledCustomCheckboxContainer = styled(CustomCheckboxContainer)`
  && {
    ${inputStyle}
    position: relative;
    cursor: pointer;
    margin-right: ${theme.space.xxs};
    padding: ${theme.space.xxs};
  }

  &[data-focused] {
    outline: none !important;
    box-shadow: none !important;
    border-color: ${theme.colors.green2};
  }

  &[data-state='checked'] > ${StyledIcon} {
    opacity: 1;
  }
`;
