/**
 * Use sparingly! Anything following this will *not* render during SSR.
 * Leading to inconsistencies between server rendered html and rehydrated page.
 * */
export const isBrowser = () => {
  if (typeof window !== 'undefined') {
    return true;
  }

  return false;
};
