import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

import { theme } from '../../../../styles/2020/theme';

type ColumnProps = React.HTMLAttributes<HTMLDivElement> &
  StyledAsProps & {
    /** Apply `space` to all children, recursively (e.g. for nested lists) */
    recursive?: boolean;
    /** Push remaining items to bottom after n items (assuming additional space) */
    splitAfter?: number;
    /** Padding enclosing all the content */
    padding?: React.CSSProperties['padding'];
    /* Align content  */
    align?: React.CSSProperties['alignItems'];
    /* Align container (if in a flex context) */
    alignSelf?: React.CSSProperties['alignSelf'];
    /* Vertical space between children */
    space?: React.CSSProperties['marginTop'];
  };

/**
 * Displays children in a vertical stack with `space` between each element.
 */
export function Column({
  recursive = false,
  space = theme.space.s,
  align = 'stretch',
  alignSelf = 'auto',
  splitAfter,
  padding,
  children,
  ...props
}: React.PropsWithChildren<ColumnProps>) {
  return (
    <ColumnContainer
      recursive={recursive}
      space={space}
      splitAfter={splitAfter}
      align={align}
      alignSelf={alignSelf}
      padding={padding}
      {...props}
    >
      {children}
    </ColumnContainer>
  );
}

const ColumnContainer = styled.div<ColumnProps>(
  ({ recursive = false, align, alignSelf, space, splitAfter, padding }) => css`
    display: flex;
    flex-direction: column;

    align-self: ${alignSelf};
    justify-content: flex-start;
    align-items: ${align};

    padding: ${padding};

    /* Increase specificity with '&&' to override single class rules */
    /* E.g. <Divider /> margin */
    && ${recursive ? '' : '>'} * {
      margin-bottom: 0;
      margin-top: 0;

      + * {
        margin-top: ${space};
      }
    }

    ${splitAfter
      ? css`
          &:only-child {
            height: 100%;
          }

          > :nth-child(${splitAfter}) {
            margin-bottom: auto;
          }
        `
      : ''}
  `
);
