import React from 'react';
import { StyledWITBGridRow } from './WITBGridRow.styles';

interface WITBGridRowProps {
  children: React.ReactNode;
}

const WITBGridRow: React.FC<WITBGridRowProps> = ({ children }) => (
  <StyledWITBGridRow>
    {children}
  </StyledWITBGridRow>
);

export default WITBGridRow;
