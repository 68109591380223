import * as React from 'react';

import { VisuallyHidden } from '@reach/visually-hidden';
import styled, { css } from 'styled-components';
import invariant from 'tiny-invariant';

import type { LooseAutoComplete } from '../../../../types/helpers';
import type { StyledAsProps, ThemeColor } from '../../../../types/props';

import { theme } from '../../../../styles/2020/theme';

import type { IconId } from '../../../../../assets/icons/CommonIcons';

type IconSize = 'matchText' | 'fontSize' | 'large';

type IconProps = React.HTMLAttributes<HTMLSpanElement> &
  StyledAsProps & {
    /* Icon to display, defined in `CommonIcons` */
    iconId?: LooseAutoComplete<IconId>;
    /* Source of svg to display (alternative to iconId) */
    src?: string;
    /* Show the icon on this side of provided text */
    side?: 'left' | 'right';
    /* Icon sizing choice */
    iconSize?: LooseAutoComplete<IconSize>;
    /* Color of icon */
    iconColor?: ThemeColor;
    /* Descriptive label for screen readers (if no accompanying text) */
    ariaText?: string;
    /* Text to display alongside icon */
    text?: React.ReactNode;
    /* Appears inline with other elements */
    inline?: boolean;
    /* Gap between icon and text */
    space?: React.CSSProperties['marginInlineEnd'];
  };

/**
 * Displays a common icon based on its `iconId`, with optional accompanying `text`.
 * If there is no accompanying text, use `ariaText` to display a descriptive label for screen readers.
 * The Icon is sized to match an uppercase letter of font-size context it appears in.
 * For an emphasized icon, use `large`.
 * If you must explicitly size an Icon, use iconSize="fontSize" and set a fontSize for this component.
 */
export const Icon = ({
  side = 'left',
  space = theme.space.xxs,
  iconSize = 'matchText',
  ariaText = '',
  text = '',
  inline = true,
  iconColor,
  iconId,
  src,
  ...props
}: IconProps) => {
  invariant(iconId || src, 'Icon: An iconId string or src must be provided');

  const SVG = () =>
    iconId ? (
      <svg aria-hidden>
        <use href={`#${iconId}`}></use>
      </svg>
    ) : (
      <img src={src} alt="" aria-hidden />
    );

  return (
    <IconContainer
      iconId={iconId}
      space={space}
      dir={side === 'left' ? 'ltr' : 'rtl'}
      iconSize={iconSize}
      iconColor={iconColor}
      text={text}
      inline={inline}
      {...props}
    >
      {ariaText && <VisuallyHidden>{ariaText}</VisuallyHidden>}
      <SVG />
      {text}
    </IconContainer>
  );
};

const iconSizes = {
  matchText: css`
    width: 0.75em;
    width: 1cap;
    height: 0.75em;
    height: 1cap;
  `,
  fontSize: css`
    width: 1em;
    height: 1em;
  `,
  large: css`
    width: 1.25em;
    height: 1.25em;
  `,
};

const IconContainer = styled.span<IconProps>(
  ({ inline, iconSize, iconColor, text, space }) => css`
    display: ${inline ? 'inline-flex' : 'flex'};
    align-items: ${iconSize === 'matchText' ? 'baseline' : 'center'};

    & img,
    & svg {
      flex-shrink: 0;
      ${iconColor && `color: ${theme.colors[iconColor]}`};
      ${iconSizes[iconSize as IconSize] ??
      css`
        width: ${iconSize};
        height: ${iconSize};
      `}
      ${text && `margin-inline-end: ${space};`}
    }
  `
);
