import * as React from 'react';

import { SkipNavLink } from '@reach/skip-nav';
import Headroom from 'react-headroom';
import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Center } from '../../layout/Center';
import { Row } from '../../layout/Row';

import { LeftButton, RightButton } from './ActionButtons';
import { DesktopMenu } from './DesktopMenu';
import { HeaderLogo } from './HeaderLogo';
import { MobileMenu, MobileMenuToggle } from './MobileMenu';
import { HeaderLinkSection, HeaderMenuProvider, useHeaderMenu } from './useHeaderMenu';

type HeaderProps = {
  linkSections: HeaderLinkSection[];
  primaryButton: React.ComponentProps<typeof LeftButton>;
  secondaryButton: React.ComponentProps<typeof RightButton>;
};

export function Header({ linkSections, primaryButton, secondaryButton }: HeaderProps) {
  return (
    <HeaderMenuProvider linkSections={linkSections}>
      <header className="hide-in-percy">
        <StyledHeadroom>
          <SkipNavLink />
          <Center stretch>
            <Row justify="space-between" gutters={theme.space.gutters}>
              <Row justify="flex-start" space="0">
                <HeaderLogo data-testid="header-logo" />
                <DesktopMenu />
              </Row>

              <DesktopButtons>
                <LeftButton {...primaryButton} />
                <RightButton {...secondaryButton} />
              </DesktopButtons>

              <MobileMenuToggle />
            </Row>
          </Center>
        </StyledHeadroom>

        <MobileMenu primaryButton={primaryButton} secondaryButton={secondaryButton} />
      </header>
      <Scrim />
    </HeaderMenuProvider>
  );
}

export const DesktopButtons = styled.div`
  display: flex;
  align-items: center;
  z-index: 20;
  margin-left: auto;
  gap: 8px;

  @media ${theme.media.max.s} {
    display: none;
  }
`;

const StyledHeadroom = styled(Headroom)`
  & .headroom {
    display: flex;
    justify-content: center;
    background: ${theme.colors.white};
    border-bottom: ${theme.borders.s} rgba(0, 0, 0, 0.03);
    /* HACK: have to override headroom default due to our own z-index war */
    z-index: 20 !important;
  }

  & .headroom--pinned {
    box-shadow: ${theme.shadows.bottomExtraLight};
  }
`;

const Scrim = () => {
  const { state } = useHeaderMenu();
  const isActive = state.activeMenuDropdown !== null;
  return <ScrimShadow isActive={isActive} />;
};

const ScrimShadow = styled.div<{ isActive: boolean }>`
  position: fixed;
  z-index: 1;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.5);
  pointer-events: none;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
`;
