// src/components/blocks/our-principles/PrinciplesImages.tsx
import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import {
  CustomisableUL,
  EmpowerUL,
  EvidenceBasedUL,
  ExceedUL,
  InclusiveUL,
  LifeUL,
  NoUserManualUL,
  QualityUL,
} from 'stile-shared/src/components/2024/common/AltUnderline/AltUnderline';

interface ImageWrapperProps {
  width: number;
  padding?: 'top' | 'bottom'; // Restrict padding to 'top' or 'bottom' for clarity
}

export const ImageWrapper = styled.div<ImageWrapperProps>`
  width: ${({ width }) => `${width}px`};
  padding: ${({ padding }) => {
    if (padding === 'bottom') return '0 0 342px 0';
    if (padding === 'top') return '142px 0 0 0';
    return '0';
  }};
  display: flex;
  flex-direction: column;
  justify-content: ${({ padding }) => {
    if (padding === 'bottom') return 'flex-start';
    if (padding === 'top') return 'flex-end';
    return 'center';
  }};
  align-items: center;
  object-position: right bottom;

  @media (max-width: 768px) {
    width: calc(100vw - 50px); /* Full width for mobile */
    padding: 0; /* Simplified padding */
    margin: 0 25px;
    justify-content: center; /* Center content */
    align-items: center; /* Center images */
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    border-radius: 16px;
    object-fit: cover; /* Cover container */
  }
`;

export const PrinciplesSectionWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    padding-top: 90px;
  }
`;

export const PrinciplesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PrinciplesHeadingContainer = styled.div`
  margin: 0;
  text-align: center;
`;

export const PrinciplesTitle = styled.h2`
  font-family: Boing;
  font-size: 55px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  color: var(--stile-green-3);
  margin: 0;
  padding: 0;

  @media (max-width: 768px) {
    font-size: 34px;
    line-height: 35px;
  }
`;

export const PrinciplesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px 0 50px 0;

  @media (max-width: 768px) {
    margin: 40px 0 50px 0;
  }

  &.bottom {
    margin-bottom: 90px;
    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }
`;

export const PrincipleItemWrapper = styled.div`
  width: 566px;
  gap: 0px;
  opacity: 0px;
  margin: 0 0 60px 0;
  @media (max-width: 768px) {
    width: 340px;
    margin: 0 30px;
  }
`;

export const PrincipleName = styled.h4`
  font-family: Boing;
  font-size: 40px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 26px;
    line-height: 28px;
  }
`;

export const PrincipleDescription = styled.p`
  font-family: Graphik;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  margin: 20px 0 0 0;
  color: var(--Primary-Stile-Grey-8, #2b2b2b);
  @media (max-width: 768px) {
    margin: 20px 0 40px 0px;
  }
`;

export const PrinciplesImageSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  padding-top: 0px;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding-top: -30px;
    gap: 20px;
  }
  @media (max-width: 768px) {
    margin-top: -30px;
    padding-bottom: 20px;
  }
`;

export const PrinciplesImage = styled(StaticImage)`
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const EvidenceBasedSVG = styled(EvidenceBasedUL)`
  @media (max-width: 768px) {
    offsety: '100px';
  }
`;

const PrinciplesSection: React.FC = () => (
  <PrinciplesSectionWrapper>
    <PrinciplesContainer>
      {/* Heading */}
      <PrinciplesHeadingContainer>
        <PrinciplesTitle>Our principles</PrinciplesTitle>
      </PrinciplesHeadingContainer>

      {/* First Principles List */}
      <PrinciplesListContainer>
        <PrincipleItemWrapper>
          <PrincipleName>
            Make <EvidenceBasedUL>evidence-based</EvidenceBasedUL> pedagogies the path of least
            resistance
          </PrincipleName>
          <PrincipleDescription>
            Teaching with Stile means teaching with evidence. We pore over education research each
            week, turning insights into practices that students will love.
          </PrincipleDescription>
        </PrincipleItemWrapper>

        <PrincipleItemWrapper>
          <PrincipleName>
            Prepare students for <LifeUL>life</LifeUL>, not just exams
          </PrincipleName>
          <PrincipleDescription>
            A great education extends beyond the curriculum. We believe it&apos;s our duty to equip
            students with the skills and knowledge they need to thrive in life—not just pass exams.
          </PrincipleDescription>
        </PrincipleItemWrapper>

        <PrincipleItemWrapper>
          <PrincipleName>
            Technology should <EmpowerUL>empower</EmpowerUL> teachers, not replace them
          </PrincipleName>
          <PrincipleDescription>
            We put teachers in the driver’s seat, using technology to enhance, not replace, their
            role.
          </PrincipleDescription>
        </PrincipleItemWrapper>
      </PrinciplesListContainer>

      {/* Images Section */}
      <PrinciplesImageSection>
        {/* First Image: 4-Person Classroom */}
        <ImageWrapper width={364} padding="bottom">
          <StaticImage
            src="./images/03-01-four-people-classroom.jpeg"
            alt="Four people in a classroom"
            placeholder="blurred"
            layout="constrained"
            width={364}
            height={364}
          />
        </ImageWrapper>

        {/* Second Image: 3-Person Looking at Screen */}
        <ImageWrapper width={564} padding="top">
          <StaticImage
            src="./images/03-02-three-men.png"
            alt="Three people looking at a screen"
            placeholder="blurred"
            layout="constrained"
            width={564}
            height={564}
            style={{ objectPosition: 'left top' }}
          />
        </ImageWrapper>
      </PrinciplesImageSection>

      {/* Second Principles List */}
      <PrinciplesListContainer className="bottom">
        <PrincipleItemWrapper>
          <PrincipleName>
            <InclusiveUL>Inclusive</InclusiveUL> by default
          </PrincipleName>
          <PrincipleDescription>
            We take a Universal Design for Learning approach, first and foremost. From language to
            topics, everything we create is designed to be accessible for every learner.
          </PrincipleDescription>
        </PrincipleItemWrapper>

        <PrincipleItemWrapper>
          <PrincipleName>
            Deeply <CustomisableUL>customisable,</CustomisableUL> because every student is different
          </PrincipleName>
          <PrincipleDescription>
            Our resources are crafted to cater to all and can be easily tailored to meet individual
            needs.
          </PrincipleDescription>
        </PrincipleItemWrapper>

        <PrincipleItemWrapper>
          <PrincipleName>
            <ExceedUL>Exceed</ExceedUL> teacher expectations
          </PrincipleName>
          <PrincipleDescription>
            Our lessons, software, and professional learning are designed to be a joy to use—every
            single day.
          </PrincipleDescription>
        </PrincipleItemWrapper>

        <PrincipleItemWrapper>
          <PrincipleName>
            <NoUserManualUL>No</NoUserManualUL> user manuals
          </PrincipleName>
          <PrincipleDescription>
            We prefer to invest the time in making features truly intuitive, so you never waste time
            deciphering step-by-step instructions.
          </PrincipleDescription>
        </PrincipleItemWrapper>

        <PrincipleItemWrapper>
          <PrincipleName>
            Relentlessly pursue <QualityUL>quality</QualityUL>
          </PrincipleName>
          <PrincipleDescription>
            We’re obsessed with quality and relentless in our drive to deliver the best science
            lessons in the known universe.
          </PrincipleDescription>
        </PrincipleItemWrapper>
      </PrinciplesListContainer>
    </PrinciplesContainer>
  </PrinciplesSectionWrapper>
);

export default PrinciplesSection;
