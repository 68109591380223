import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';
import type { StileGtagEventName } from '../../../../types/services';

import { GtagEventParams, tagEvent } from '../../../../utils/tagEvent';

import { theme } from '../../../../styles/2020/theme';

export type ButtonVariant = 'cta' | 'secondary' | 'dark';

export type ButtonProps = React.PropsWithChildren<
  StyledAsProps & {
    gtagEvent?: StileGtagEventName;
    gtagEventParams?: GtagEventParams;
    variant?: ButtonVariant;
    stretch?: boolean;
    text?: string;
    type?: React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>['type'] | null;
    onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
    onMouseDown?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
    tabIndex?: React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>['tabIndex'];
    disabled?: React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>['disabled'];
    style?: React.ButtonHTMLAttributes<HTMLButtonElement | HTMLAnchorElement>['style'];
  }
>;

export const Button = ({
  gtagEvent,
  gtagEventParams,
  variant = 'cta',
  type = 'button',
  text,
  children,
  onMouseDown,
  ...props
}: ButtonProps) => {
  const handleMouseDown = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      gtagEvent && tagEvent(gtagEvent, gtagEventParams);
      onMouseDown && onMouseDown(event);
    },
    [gtagEvent, gtagEventParams, onMouseDown]
  );

  return (
    <ButtonContainer {...props} type={type} variant={variant} onMouseDown={handleMouseDown}>
      {text ?? children}
    </ButtonContainer>
  );
};

/* Also used by IconButton */
export const buttonBaseStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  text-align: center;
  cursor: pointer;

  transition: filter ${theme.transitions.short};

  &:hover {
    filter: brightness(1.1);
  }
`;

const ButtonContainer = styled.button<ButtonProps>`
  ${buttonBaseStyles}
  ${({ stretch }) => stretch && 'width: 100%;'}
  font-size: ${theme.fontSizes.s};
  font-weight: 500;

  border: ${theme.borders.s};
  border-radius: ${theme.borderRadius.s};
  padding: ${theme.space.xxs} ${theme.space.s};

  ${({ variant }) => {
    if (variant === 'cta') {
      return css`
        && {
          color: ${theme.colors.dark};
          background-color: ${theme.colors.brand};
          border-color: ${theme.colors.brand};
          /* Global style not effective for above colors */
          &:focus-visible {
            box-shadow: inset 0 0 0 2px ${theme.colors.white};
          }
        }
      `;
    }

    if (variant === 'secondary') {
      return css`
        && {
          color: ${theme.colors.dark};
          background-color: ${theme.colors.white};
          border-color: ${theme.colors.brand};
          /* Global style not effective for above colors */
          &:focus-visible {
            box-shadow: inset 0 0 0 2px ${theme.colors.dark};
          }
        }
      `;
    }

    if (variant === 'dark') {
      return css`
        && {
          color: ${theme.colors.white};
          background-color: ${theme.colors.dark};
          border-color: ${theme.colors.dark};
        }
      `;
    }
  }};
`;
