import * as React from 'react';

import { useQuery } from 'react-query';

import type {
  StileJobCategoryName,
  StileJobDetailType,
  StileJobLocationName,
} from '../../../../types/data';

import { CONTACT } from '../../../../constants';

import { FilteredListing, getFilteredListings } from '../../../../utils/getFilteredListings';

import { FilteredListings } from '../../common/FilteredListings';
import { Link } from '../../common/Link';
import { P } from '../../common/Primitives';
import { Select } from '../../common/Select';

import { JobDetail } from './JobDetail';
import { fetchStileJobs } from './utils';

type JobsListBlockProps = {
  title?: string;
  category?: StileJobCategoryName;
  location?: StileJobLocationName;
  knownJobIds?: Array<string>;
};

export function JobsListBlock({
  title = 'Open positions',
  category = 'All categories',
  location = 'All locations',
  knownJobIds,
}: JobsListBlockProps) {
  const [filterCategory, setFilterCategory] = React.useState(category);
  const [filterLocation, setFilterLocation] = React.useState(location);
  const { items, locations, categories, isLoading, isFetched, isError } = useJobs();

  const listings = getFilteredListings<StileJobDetailType>(
    items,
    categories,
    filterCategory,
    filterLocation,
    'department',
    'city'
  );

  const locationOptions = locations.map((item) => ({
    value: item,
    text: item,
  }));

  const categoryOptions = listings.map((item) => ({
    value: item.slug,
    text: `${item.slug} (${item.count})`,
  }));

  const onLocationSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterLocation(e.target.value as StileJobLocationName);
  };

  const onCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilterCategory(e.target.value as StileJobCategoryName);
  };

  const filters = (
    <>
      <Select options={locationOptions} onChange={onLocationSelect} defaultValue={filterLocation} />
      <Select options={categoryOptions} onChange={onCategorySelect} defaultValue={filterCategory} />
    </>
  );

  let errorMessage = null;

  if (isError) {
    errorMessage = (
      <P>
        Whoops! We don’t seem to be able to list our jobs right now. Try to refresh the page, or get
        in touch with us at <Link url={CONTACT.email.help.url}>{CONTACT.email.help.url}</Link>.
      </P>
    );
  } else if (isFetched && getActiveListingsLength(listings, filterCategory) == 0) {
    // `listings` will always contain the "All categories" listing, even if there is
    // no jobs to list
    errorMessage = (
      <P>
        We don’t seem to have any open positions at the moment. Adjust your filter selections or
        check back at a later date.
      </P>
    );
  }

  return (
    <FilteredListings
      title={title}
      deepLink="OpenPositions"
      filters={filters}
      errorMessage={errorMessage}
      isLoading={isLoading}
      isError={isError}
      listings={listings}
      ListingComponent={JobDetail}
      knownJobIds={knownJobIds}
    />
  );
}

function useJobs() {
  const { isLoading, isFetched, isError, data } = useQuery('jobs', fetchStileJobs, {
    refetchOnWindowFocus: false,
  });

  const categories = [
    'All categories',
    ...Array.from(new Set(data?.jobs?.map((job) => job.department))),
  ];

  const locations = ['All locations', ...Array.from(new Set(data?.jobs?.map((job) => job.city)))];

  return {
    items: data?.jobs || [],
    isLoading,
    isFetched,
    isError,
    categories,
    locations,
  };
}

function getActiveListingsLength(
  listings: FilteredListing<StileJobDetailType[]>[],
  filterCategory: string
) {
  for (const listing of listings) {
    if (listing.slug == filterCategory) {
      return listing.count;
    }
  }

  return 0;
}
