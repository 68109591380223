import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

import { theme } from '../../../../styles/2020/theme';

type CenterProps = React.HTMLAttributes<HTMLDivElement> &
  StyledAsProps & {
    /** The minimum space on either side of the content */
    gutters?: React.CSSProperties['padding'];
    /** Constrain content within this value, with equal space on either side  */
    max?: React.CSSProperties['maxWidth'];
    /** Content will always fill `max` width */
    stretch?: boolean;
    /** Center child elements based on their content width rather than Center's width */
    intrinsic?: boolean;
    /** Align text */
    textAlign?: React.CSSProperties['textAlign'];
  };

/**
 * Centers content based on several rules:
 *`max`: center content with equal spacing on either side (when above `max`).
 *`intrinsic`: center content based on its own width (within `max` width).
 *`textAlign`: align text within the content.
 */
export const Center = ({
  gutters = '0',
  max = theme.space.siteWidth,
  stretch = false,
  intrinsic = false,
  children,
  ...props
}: CenterProps) => (
  <CenterContainer gutters={gutters} max={max} stretch={stretch} intrinsic={intrinsic} {...props}>
    {children}
  </CenterContainer>
);

const intrinsicStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CenterContainer = styled.div<CenterProps>(
  ({ textAlign, gutters, intrinsic, max, stretch }) => css`
    && {
      ${intrinsic ? intrinsicStyles : ''}
      box-sizing: content-box;
      margin-left: auto;
      margin-right: auto;
      width: ${stretch ? '100%' : 'auto'};
      max-width: ${max};
      padding-left: ${gutters};
      padding-right: ${gutters};
      text-align: ${textAlign};
    }
  `
);
