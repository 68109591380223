import styled from 'styled-components';

export const StyledWITBGridItem = styled.div`
  line-height: 24px;
  text-align: left;
  background: var(--white);
  padding: 0;
  flex: 0 0 calc(33.3333333% - (28px / 3 * 2));

  .WITBGridItem {
    flex: 0 0 auto;
    scroll-snap-align: start;
    padding: 10px;
  }

  .icon-title-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0;
    @media (max-width: 768px) {
      margin-bottom: 15px;
    }
  }

  .icon-container {
    width: 38px;
    height: 38px;
    margin: 0px;
    padding: 0px;
    margin-right: 16px;
  }

  .grid-title {
    font-size: 21px;
    font-weight: 600;
    text-align: left;
    margin: 0px;
    padding: 0px;
  }

  .grid-content p {
    font-size: 16px;
    line-height: 21px;
    color: var(--stile-grey-8);
    margin: 0px;
    padding: 0px;
  }

  @media (max-width: 768px) {
    gap: 0;
    max-width: 330px;
    scroll-snap-align: left;

    width: 310px;
    flex: 0 0 auto;
    margin-bottom: 28px;
    padding: 0;

    &:last-child {
      margin-right: 0;
    }
  }
`;
