import * as React from 'react';

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { useInView } from 'react-intersection-observer';

import { StyledSlider } from './Slider.styles';

type SliderProps = React.HTMLAttributes<HTMLDivElement> & {
  tabs: string[];
  headings: string[];
  paragraphs: string[];
  whatYouWillLearn?: string[][];
  images: React.ReactNode[];
  secondsToFlip?: number;
};

/**
 * Displays Slider
 */
export const Slider = ({
  tabs,
  headings,
  paragraphs,
  whatYouWillLearn = undefined,
  images,
  secondsToFlip = 10,
  ...props
}: React.PropsWithChildren<SliderProps>) => {
  const [sliderIndex, setSliderIndex] = React.useState(0);
  const [autoPlay, setAutoPlay] = React.useState(true);
  const [progress, setProgress] = React.useState(0);
  const sliderRef = React.useRef<HTMLDivElement>(null);

  function setSliderIndexAndPauseAutoPlay(index: number, e: React.MouseEvent<HTMLButtonElement>) {
    setAutoPlay(false);
    setProgress(100);
    setSliderIndex(index);
  }

  React.useEffect(() => {
    function progressAutoPlay() {
      if (autoPlay) {
        setProgress((prevProgress) => {
          if (prevProgress < 100) {
            return prevProgress + 1;
          } else {
            setSliderIndex(() => {
              if (sliderIndex < tabs.length - 1) {
                return sliderIndex + 1;
              } else {
                return 0;
              }
            });
            return 0;
          }
        });
      }
    }

    const millisecondsPerPercent = (secondsToFlip * 1000) / 100;

    const interval = setInterval(progressAutoPlay, millisecondsPerPercent);
    return () => clearInterval(interval);
  }, [autoPlay, progress, sliderIndex, tabs.length]);

  React.useEffect(() => {
    if (sliderRef.current) {
      try {
        ScrollTrigger.create({
          trigger: sliderRef.current,
          start: 'top 90%',
          end: 'bottom top', // Ensure this covers the entire element
          onEnter: () => {
            // console.log('setAutoPlay true');
            setAutoPlay(true);
          },
          onEnterBack: () => {
            // console.log('setAutoPlay true');
            setAutoPlay(true);
          },
          onLeave: () => {
            // console.log('setAutoPlay false');
            setAutoPlay(false);
          },
          onLeaveBack: () => {
            // console.log('setAutoPlay false');
            setAutoPlay(false);
          },
        });
      } catch (error) {
        console.log('error', error);
      }
    }
  }, []);

  return (
    <StyledSlider
      className="slider"
      ref={sliderRef}
      $animationLength={`${secondsToFlip / 100}s`}
      {...props}
    >
      <div className="--header">
        {tabs.map((tab, i) => (
          <button
            key={i}
            aria-roledescription="tab"
            role="tab"
            className={`--headerButton ${sliderIndex === i ? `--active --active-${progress}` : ''}`}
            onClick={(e) => setSliderIndexAndPauseAutoPlay(i, e)}
          >
            <h6 className="h7">{tab}</h6>
          </button>
        ))}
      </div>

      {/* Render content based on tabs */}
      {tabs.map((tab, i) => (
        <div key={i} className="--content-container">
          <button
            aria-roledescription="tab"
            role="tab"
            className={`--headerButton ${sliderIndex === i ? `--active --active-${progress}` : ''}`}
            onClick={(e) => setSliderIndexAndPauseAutoPlay(i, e)}
          >
            <h6 className="h8">{tabs[i]}</h6>

            {sliderIndex !== i && (
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6L10.75 14C10.75 14.4142 10.4142 14.75 10 14.75C9.58579 14.75 9.25 14.4142 9.25 14L9.25 6C9.25 5.58579 9.58579 5.25 10 5.25Z"
                  fill="#DAD4CC"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M14.75 10C14.75 10.4142 14.4142 10.75 14 10.75L6 10.75C5.58579 10.75 5.25 10.4142 5.25 10C5.25 9.58579 5.58579 9.25 6 9.25L14 9.25C14.4142 9.25 14.75 9.58579 14.75 10Z"
                  fill="#DAD4CC"
                />
              </svg>
            )}
          </button>
          <div className={`--content ${i === sliderIndex ? `--active --active-${progress}` : ''}`}>
            <div className="--text">
              <h4>{headings[i]}</h4>
              <p>{paragraphs[i]}</p>
              {whatYouWillLearn && (
                <>
                  <p className="whatYoullLearn">What you’ll learn:</p>
                  <ul>
                    {i === sliderIndex &&
                      (whatYouWillLearn[i] || []).map((text, index) => <li key={index}>{text}</li>)}
                  </ul>
                </>
              )}
            </div>
            <div className="--image">{images[i]}</div>
          </div>
        </div>
      ))}
    </StyledSlider>
  );
};
