import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Column } from '../../layout/Column';

export type FormProps = React.ComponentProps<typeof Column> & {};

export const Form = (props: React.PropsWithChildren<FormProps>) => {
  return <FormContainer forwardedAs="form" space={theme.space.s} {...props} />;
};

export const FormContainer = styled(Column)<FormProps>`
  font-size: ${theme.fontSizes.s};
`;
