import styled from 'styled-components';

export const StyledWITBTopSection = styled.div`
  text-align: center;
  padding: 80px 0 0 0;

  h2 {
    color: var(--stile-grey-8);
    margin: 0 0 32px 0;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
  }

  p {
    font-weight: 100;
    font-size: 16px;
    color: var(--stile-grey-8);
    max-width: 563px;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 40px 0 0 0;

    h2 {
      margin: 0 0 25px 0;
      font-size: 38px;
    }

    p {
      font-size: 16px;
    }
  }
`;
