import * as React from 'react';

import { Button } from '../../common/Button';
import { Link } from '../../common/Link';

/**
 * Only Button variant will be accepted, Link variant will not be passed along,
 * and unneeded since this component has button styling anyway.
 * Button type not accepted since a Link will be rendered.
 */
type ButtonLinkProps = React.ComponentProps<typeof Button> &
  Omit<React.ComponentProps<typeof Link>, 'variant' | 'type'>;

// NOTE: typing a dynamic button that can switch to button link causes a lot grief
// It's easier to just explicitly use Button or ButtonLink as needed
export const ButtonLink = ({ variant, ...props }: ButtonLinkProps) => {
  return <Button {...props} as={Link} variant={variant} type={null} />;
};
