import * as React from 'react';

import { useWindowScroll } from 'react-use';

export const useRunOnScroll = ({
  isActive,
  callback,
}: {
  isActive: boolean;
  callback: () => void;
}) => {
  const scrollRef = React.useRef<number>();
  const { y } = useWindowScroll();

  React.useEffect(() => {
    scrollRef.current = y;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!isActive) {
      scrollRef.current = y;
      return;
    }

    if (scrollRef.current !== y) {
      scrollRef.current = y;
      callback();
    }
  }, [isActive, y, callback]);
};
