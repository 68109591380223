import React from 'react';
import { StyledWITBStatItem } from './WITBStatItem.styles';

interface WITBStatItemProps {
  value: string;
  label: string;
}

const WITBStatItem: React.FC<WITBStatItemProps> = ({ value, label }) => (
  <StyledWITBStatItem>
    <h2>{value}</h2>
    <h6>{label}</h6>
  </StyledWITBStatItem>
);

export default WITBStatItem;
