import styled from 'styled-components';

export const StyledWITBStatsSection = styled.section`
  background-color: var(--stile-grey-8);
  color: var(--white);
  text-align: center;
  padding: 80px 20px 40px 20px;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h4 {
    padding: 0 0 10px 0;
    margin: 0;
    font-size: 40px;
    line-height: 45px;
    color: var(--white);
  }

  @media (max-width: 1160px) {
    .content {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 40px 10px;
    margin-top: 30px;

    h4 {
      font-size: 2rem;
    }
  }
`;
