import React from 'react';
import { StyledWITBUnderlinedText } from './WITBUnderlinedText.styles';

interface WITBUnderlinedTextProps {
  children: React.ReactNode;
}

const WITBUnderlinedText: React.FC<WITBUnderlinedTextProps> = ({ children }) => (
  <StyledWITBUnderlinedText>
    {children}
  </StyledWITBUnderlinedText>
);

export default WITBUnderlinedText;
