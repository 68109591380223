import * as React from 'react';

type InputProps = {
  name: string;
  label: string;
  type?: HTMLInputElement['type'];
  placeholder?: HTMLInputElement['placeholder'];
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ type = 'text', name, label, placeholder, ...props }, ref) => (
    <>
      <label htmlFor={name}>{label}</label>
      <input id={name} name={name} type={type} placeholder={placeholder} {...props} ref={ref} />
    </>
  )
);

Input.displayName = 'Input';
