// ScrollIndicator.tsx
import React from 'react';

import s1of2 from 'stile-shared/static/scroll-01-02.svg';
import s1of3 from 'stile-shared/static/scroll-01-03.svg';
import s2of2 from 'stile-shared/static/scroll-02-02.svg';
import s2of3 from 'stile-shared/static/scroll-02-03.svg';
import s3of3 from 'stile-shared/static/scroll-03-03.svg';
import styled from 'styled-components';

const ScrollIndicatorWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    bottom: 0;
    left: 0;
    position: absolute;
    width: 112px;
    /* Ensure it doesn't scroll with the content */
    flex-shrink: 0;
  }
`;

const ScrollIndicatorImage = styled.img`
  width: 50px;
  height: auto;
`;

const PageText = styled.div`
  font-size: 14px;
  width: 60px;
  transform-y: 15px;
  color: #706d69;
  text-align: right;
`;

interface ScrollIndicatorProps {
  currentIndex: number;
  totalCount: number;
}

const ScrollIndicator: React.FC<ScrollIndicatorProps> = ({ currentIndex, totalCount }) => {
  const renderSVG = () => {
    if (totalCount === 2) {
      return currentIndex === 0 ? s1of2 : s2of2;
    } else if (totalCount === 3) {
      return [s1of3, s2of3, s3of3][currentIndex];
    }
    return s1of3; // Default fallback
  };

  return (
    <ScrollIndicatorWrapper>
      <ScrollIndicatorImage
        src={renderSVG()}
        alt={`Scroll indicator showing page ${currentIndex + 1} of ${totalCount}`}
      />
      <PageText>
        {currentIndex + 1} of {totalCount}
      </PageText>
    </ScrollIndicatorWrapper>
  );
};

export default ScrollIndicator;
