import styled from 'styled-components';

export const StyledWITBTile = styled.div`
  width: 156px;
  align-items: flex-start;
  padding: 0px;
  flex-shrink: 0;
  border-radius: 15px;
  word-wrap: true;
  cursor: pointer;

  .image-container {
    width: 151px;
    height: 156px;
    padding: 1px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    border-radius: 15px;
    background: var(--stile-grey-1);
    box-shadow: 0px 3px 5px rgba(1, 1, 1, 0.05);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .tile-content {
    width: 156px;
    text-align: left;
    margin-top: 10px;
    line-height: 21px;
    font-size: 14px;
    flex-wrap: wrap;

    .tile-title {
      margin: 15px 0 0 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 1.2;
    }

    p {
      margin: 0;
      color: var(--stile-grey-8);
      word-wrap: wrap;
      max-width: 156px;
    }
  }

  @media (max-width: 768px), (hover: none) and (pointer: coarse) {
    width: 156px;
    margin: 0 4px;
    scroll-snap-align: center;
    .content {
      transform: translateX(-18px);
    }
  }
`;
