import * as React from 'react';

import styled, { keyframes } from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Column } from '../../layout/Column';
import { FullWidthBackground } from '../../layout/FullWidthBackground';
import { Imposter } from '../../layout/Imposter';

import StarsColoured from './west_ed_stars_coloured.svg';
import StarsDim from './west_ed_stars_dim.svg';

export function TwinklingStarsBlock({ children }: React.PropsWithChildren<{}>) {
  return (
    <FullWidthBackground forwardedAs="section" textAlign="center" invertColors>
      <Column align="center">
        {children}
        <StarsBackground />
      </Column>
    </FullWidthBackground>
  );
}

function StarsBackground() {
  return (
    <StarsBackgroundContainer>
      {/* images are decorative and do not require alt text */}
      <StarImg reverse src={StarsColoured} alt="" />
      <StarImg src={StarsDim} alt="" />
    </StarsBackgroundContainer>
  );
}

const fade = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

const StarsBackgroundContainer = styled(Imposter)`
  width: 90%;
  height: 100%;
  max-width: ${theme.breakpoints.xl};
  overflow: hidden;
  pointer-events: none;
`;

const StarImg = styled.img<{ reverse?: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  animation-name: ${fade};
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-direction: ${(props) => (props.reverse ? 'alternate-reverse' : 'alternate')};
`;
