import * as React from 'react';

import { useInView } from 'react-intersection-observer';
import styled, { css } from 'styled-components';

type SliderProps = React.HTMLAttributes<HTMLDivElement> & {
  color?: Colors;
  titles: string[];
  callOuts: (React.ReactElement | string)[][];
  images: React.ReactElement[];
};

const generateHeightStyles = () => {
  let styles = '';
  for (let i = 0; i <= 100; i += 10) {
    styles += `
      &.--active-${i}::after {
        height: ${i}%;
      }
    `;
  }
  return css`
    ${styles}
  `;
};

const StyledSlider = styled.div`
  grid-column: 1 / span 12;
  margin-top: 100px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;

  .progress-bar {
    position: absolute;
    top: 0;
    left: 0;

    width: 10px;
    height: 100%;
    background-color: blue;

    transform-origin: top left;
  }

  @media (max-width: 768px) {
    margin-top: 60px;
  }

  --base-color: var(--stile-grey-3);
  --active-color: var(--stile-grey-8);

  &.--stile-grey-8 {
    --active-color: var(--stile-grey-8);
  }

  &.--white {
    --active-color: var(--white);
  }

  .--tabs {
    padding-top: 80px;
    padding-bottom: 124px;
    grid-column: 1 / span 5;
    position: relative;
    @media (max-width: 768px) {
      grid-column: 1 / span 12;
      padding: 0;
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 45px;
      position: relative;

      .--break {
        flex-basis: 100%;
        height: 0;
      }

      .--expand-image {
        display: none;
      }

      .--header {
        align-content: end;
        padding-left: 20px;
        position: relative;
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-align: left;
        h4 {
          margin: 0 0 25px 0;
        }

        ul {
          margin: 0;
          padding: 0;
          display: flex;
          width: 100%;
          flex-direction: column;
          list-style-type: disc;
          gap: 12px;
          @media (max-width: 768px) {
            width: auto;
          }

          li {
            margin-left: 20px;
          }
        }
      }

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background-color: var(--stile-grey-3);
        border-radius: 2px;
      }

      h4,
      ul {
        color: var(--active-color);
      }

      &:not(.--active) {
        h4 {
          color: var(--base-color);
          margin-bottom: 0;
        }
        ul {
          display: none;
        }
      }

      &.--active {
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: -1px;
          width: 6px;
          height: 0%;
          background-color: var(--stile-green-1);
          border-radius: 2px;
          transition: height 1s linear;
        }
        ${generateHeightStyles()}
      }

      /* FIXME: always show on mobile overrides */
      @media (max-width: 768px) {
        &:last-child {
          margin-bottom: 0;
        }
        .--expand-image {
          display: flex;
          flex: 1 0 100%;
          width: auto;
          box-shadow: 0px 43px 26px 0px rgba(0, 0, 0, 0.04), 0px 19px 19px 0px rgba(0, 0, 0, 0.06),
            0px 5px 11px 0px rgba(0, 0, 0, 0.07);
          border-radius: 32px;
        }

        .--header {
          flex: 1 0 100%;
        }

        &:before {
          display: none;
        }
        &:after {
          display: none;
          content: '';
          position: absolute;
          top: 0;
          left: -1px;
          width: 6px;
          height: 75%;
          background-color: var(--stile-green-1);
          border-radius: 2px;
        }
        button {
          color: var(--active-color) !important;
        }
        h4 {
          color: var(--active-color);
          margin-top: 35px !important;
        }
        ul {
          display: flex !important;
        }
      }
    }
  }

  .--images {
    grid-column: 6 / span 7;
    padding: 0 45px;
    @media (max-width: 768px) {
      display: none;
    }
    > div {
      display: none;
      &.--active {
        display: block;
      }
      .gatsby-image-wrapper {
        border-radius: 32px;
      }
    }
  }

  &.items-1 {
    // background-color: red;
    .--tabs {
      > div {
        :after,
        :before {
          display: none;
        }

        @media (max-width: 768px) {
          .--header {
            padding-left: 0;
          }
        }
      }
    }
  }
`;

/**
 * Displays Slider
 */
export const OtherSlider = ({
  color,
  titles,
  callOuts,
  images,
  ...props
}: React.PropsWithChildren<SliderProps>) => {
  const [sliderIndex, setSliderIndex] = React.useState(0);
  const [autoPlay, setAutoPlay] = React.useState(true);
  const [progress, setProgress] = React.useState(0);

  const { ref, inView } = useInView();

  function setSliderIndexAndPauseAutoPlay(index: number) {
    setAutoPlay(false);
    setProgress(100);
    setSliderIndex(index);
  }

  const secondsToFlip = 10;
  const millisecondsPerPercent = (secondsToFlip * 1000) / 100;

  React.useEffect(() => {
    function progressAutoPlay() {
      // console.log(`progressAutoPlay: ${autoPlay} | ${progress} | ${sliderIndex}`);
      if (autoPlay && inView) {
        setProgress((prevProgress) => {
          if (prevProgress < 100) {
            return prevProgress + 10;
          } else {
            setSliderIndex(() => {
              if (sliderIndex < titles.length - 1) {
                return sliderIndex + 1;
              } else {
                return 0;
              }
            });
            return 0;
          }
        });
      }
    }

    const interval = setInterval(progressAutoPlay, millisecondsPerPercent * 10);
    return () => clearInterval(interval);
  }, [inView, autoPlay, progress, sliderIndex, titles.length]);

  return (
    <StyledSlider ref={ref} className={`--otherSlider ${color} items-${titles.length}`} {...props}>
      {/* <div className="progress-bar"></div> */}

      <div className="--tabs">
        {titles.map((title, index) => (
          <div className={sliderIndex === index ? `--active --active-${progress}` : ''} key={index}>
            <div className="--expand-image">{images[index]}</div>
            <div className="--break"></div>
            <button className="--header" onClick={() => setSliderIndexAndPauseAutoPlay(index)}>
              <h4>{title}</h4>
              <ul>
                {callOuts[index].map((callOut, index) => (
                  <li key={index}>{callOut}</li>
                ))}
              </ul>
            </button>
          </div>
        ))}
      </div>
      <div className="--images">
        {images.map((image, index) => (
          <div key={index} className={sliderIndex === index ? '--active' : ''}>
            {image}
          </div>
        ))}
      </div>
    </StyledSlider>
  );
};
