import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

import { theme } from '../../../../styles/2020/theme';

type SwitcherProps = StyledAsProps & {
  /* Maximum number of items permitted for a horizontal layout */
  limit?: number;
  /* Dimension at which the layout will forcibly switch */
  threshold?: React.CSSProperties['width'];
  /* Gap between elements */
  space?: React.CSSProperties['gap'];
  /* Align items */
  align?: React.CSSProperties['alignItems'];
};

/**
 * Displays children in a row, switching to a vertical layout when below `threshold`.
 * Also switches if `limit` number of child elements cannot fit horizontally.
 */
export const Switcher = ({
  limit = 4,
  threshold = `${parseInt(theme.space.siteWidth) / 2}px`,
  space = theme.space.l,
  children,
  ...props
}: React.PropsWithChildren<SwitcherProps>) => {
  return (
    <SwitcherContainer limit={limit} space={space} threshold={threshold} {...props}>
      {children}
    </SwitcherContainer>
  );
};

const SwitcherContainer = styled.div<SwitcherProps>(
  ({ limit = 0, space, align, threshold }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${space};
    align-items: ${align};

    /* Switch the layout at the ${threshold} */
    > * {
      flex-grow: 1;
      flex-basis: calc((${threshold} - 100%) * 999);
    }

    /* Switch to a vertical configuration if there are more than ${limit} child elements */
    > :nth-last-child(n + ${limit + 1}),
    > :nth-last-child(n + ${limit + 1}) ~ * {
      flex-basis: 100%;
    }
  `
);
