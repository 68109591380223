import React from 'react';
import { useEffect, useRef } from 'react';

import styled from 'styled-components';

import HowSVG from './images/01-underline-how.svg';
import EmpowerSVG from './images/03-a-underline-empower.svg';
import EvidenceBasedSVG from './images/03-a-underline-evidence-based.svg';
import LifeSVG from './images/03-a-underline-life.svg';
import CustomisableSVG from './images/03-b-underline-customisable.svg';
import ExceedSVG from './images/03-b-underline-exceed.svg';
import InclusiveSVG from './images/03-b-underline-inclusive.svg';
import NoUserManualSVG from './images/03-b-underline-no-user.svg';
import QualitySVG from './images/03-b-underline-quality.svg';
import CustomisableSVG_mobile from './images/mobile/customisable.svg';
import EmpowerSVG_mobile from './images/mobile/empower.svg';
import EvidenceBasedSVG_mobile from './images/mobile/evidence-based.svg';
import ExceedSVG_mobile from './images/mobile/exceed.svg';
import HowSVG_mobile from './images/mobile/how.svg';
import InclusiveSVG_mobile from './images/mobile/inclusive.svg';
import LifeSVG_mobile from './images/mobile/life.svg';
import NoUserManualSVG_mobile from './images/mobile/no.svg';
import QualitySVG_mobile from './images/mobile/quality.svg';

// Define a generic underline component to reuse styles
interface AltUnderlineProps {
  children: React.ReactNode;
  desktopSvgName: string;
  mobileSvgName: string;
  offsetX?: string;
  offsetY?: string;
  width?: string;
}

const AltUnderlineWrapper = styled.span<{
  desktopSvgName: string;
  mobileSvgName: string;
  offsetX: string;
  offsetY: string;
  width: string;
}>`
  position: relative;
  display: inline-block;

  & .underline-image {
    position: absolute;
    left: ${(props) => props.offsetX};
    bottom: ${(props) => props.offsetY};
    width: ${(props) => props.width ?? '110%'};
    height: 1em;
    background: url(${(props) => props.desktopSvgName}) no-repeat;
    z-index: 4;
    transform: scaleX(1);
    transform-origin: left;

    @media (max-width: 768px) {
      background: url(${(props) => props.mobileSvgName}) no-repeat;
      bottom: calc(${(props) => props.offsetY} - 5%);
    }
  }

  & span.text-content {
    position: relative;
    z-index: 5;
  }
`;

const AltUnderline: React.FC<AltUnderlineProps> = ({
  children,
  desktopSvgName,
  mobileSvgName,
  offsetX = '0',
  offsetY = '-50%',
  width = '110%',
}) => {
  const underlineRef = useRef<HTMLSpanElement>(null);

  // useEffect(() => {
  //   if (underlineRef.current) {
  //     const underlineImage = underlineRef.current.querySelector('.underline-image');
  //     if (underlineImage) {
  //       gsap.to(underlineImage, {
  //         duration: 1,
  //         scaleX: 1,
  //         ease: 'power2.out',
  //       });
  //     }
  //   }
  // }, []);

  return (
    <AltUnderlineWrapper
      ref={underlineRef}
      desktopSvgName={desktopSvgName}
      mobileSvgName={mobileSvgName}
      offsetX={offsetX}
      offsetY={offsetY}
      width={width}
    >
      <span className="text-content">{children}</span>
      <span className="underline-image"></span>
    </AltUnderlineWrapper>
  );
};

// Create individual underline components with default offsets
export const InclusiveUL: React.FC<{
  children: React.ReactNode;
  offsetX?: string;
  offsetY?: string;
}> = ({ children, offsetX = '-5%', offsetY = '-75%' }) => (
  <AltUnderline
    desktopSvgName={InclusiveSVG}
    mobileSvgName={InclusiveSVG_mobile}
    offsetX={offsetX}
    offsetY={offsetY}
  >
    {children}
  </AltUnderline>
);

export const QualityUL: React.FC<{
  children: React.ReactNode;
  offsetX?: string;
  offsetY?: string;
}> = ({ children, offsetX = '0', offsetY = '-70%' }) => (
  <AltUnderline
    desktopSvgName={QualitySVG}
    mobileSvgName={QualitySVG_mobile}
    offsetX={offsetX}
    offsetY={offsetY}
  >
    {children}
  </AltUnderline>
);

export const CustomisableUL: React.FC<{
  children: React.ReactNode;
  offsetX?: string;
  offsetY?: string;
}> = ({ children, offsetX = '-5%', offsetY = '-30%' }) => (
  <AltUnderline
    desktopSvgName={CustomisableSVG}
    mobileSvgName={CustomisableSVG_mobile}
    offsetX={offsetX}
    offsetY={offsetY}
  >
    {children}
  </AltUnderline>
);

export const ExceedUL: React.FC<{
  children: React.ReactNode;
  offsetX?: string;
  offsetY?: string;
}> = ({ children, offsetX = '-10%', offsetY = '-75%' }) => (
  <AltUnderline
    desktopSvgName={ExceedSVG}
    mobileSvgName={ExceedSVG_mobile}
    offsetX={offsetX}
    offsetY={offsetY}
  >
    {children}
  </AltUnderline>
);

export const NoUserManualUL: React.FC<{
  children: React.ReactNode;
  offsetX?: string;
  offsetY?: string;
  width?: string;
}> = ({ children, width = '150%', offsetX = '-20%', offsetY = '-75%' }) => (
  <AltUnderline
    desktopSvgName={NoUserManualSVG}
    mobileSvgName={NoUserManualSVG_mobile}
    width={width}
    offsetX={offsetX}
    offsetY={offsetY}
  >
    {children}
  </AltUnderline>
);

export const LifeUL: React.FC<{
  children: React.ReactNode;
  offsetX?: string;
  offsetY?: string;
  width?: string;
}> = ({ children, width = '150%', offsetX = '-15%', offsetY = '-65%' }) => (
  <AltUnderline
    desktopSvgName={LifeSVG}
    mobileSvgName={LifeSVG_mobile}
    offsetX={offsetX}
    offsetY={offsetY}
    width={width}
  >
    {children}
  </AltUnderline>
);

export const EmpowerUL: React.FC<{
  children: React.ReactNode;
  offsetX?: string;
  offsetY?: string;
}> = ({ children, offsetX = '0', offsetY = '-70%' }) => (
  <AltUnderline
    desktopSvgName={EmpowerSVG}
    mobileSvgName={EmpowerSVG_mobile}
    offsetX={offsetX}
    offsetY={offsetY}
  >
    {children}
  </AltUnderline>
);

export const EvidenceBasedUL: React.FC<{
  children: React.ReactNode;
  offsetX?: string;
  offsetY?: string;
}> = ({ children, offsetX = '0', offsetY = '-60%' }) => (
  <AltUnderline
    desktopSvgName={EvidenceBasedSVG}
    mobileSvgName={EvidenceBasedSVG_mobile}
    offsetX={offsetX}
    offsetY={offsetY}
  >
    {children}
  </AltUnderline>
);

export const HowUL: React.FC<{ children: React.ReactNode; offsetX?: string; offsetY?: string }> = ({
  children,
  offsetX = '0',
  offsetY = '-70%',
}) => (
  <AltUnderline
    desktopSvgName={HowSVG}
    mobileSvgName={HowSVG_mobile}
    offsetX={offsetX}
    offsetY={offsetY}
  >
    {children}
  </AltUnderline>
);

// Usage example:
// import { InclusiveUL, QualityUL } from 'templates/2024/CustomUnderline';
// <p>
//   Hey this is a sentence about <InclusiveUL>Inclusive</InclusiveUL> and it is <QualityUL>Quality</QualityUL>
// </p>

export default AltUnderline;
