import * as React from 'react';

import { useQueryClient } from 'react-query';

import type { StileJobDetailType } from '../../../../types/data';

import { theme } from '../../../../styles/2020/theme';

import { Link } from '../../common/Link';
import { Divider, Separator, Text } from '../../common/Primitives';
import { Column } from '../../layout/Column';
import { Row } from '../../layout/Row';

import { fetchStileJobById } from '../JobDescriptionBlock/utils';

type JobDetailProps = StileJobDetailType & { knownJobIds?: Array<string> };

export function JobDetail({
  shortcode,
  title,
  city,
  department,
  employment_type,
  knownJobIds,
}: JobDetailProps) {
  const queryClient = useQueryClient();

  const prefetchJob = async () => {
    await queryClient.prefetchQuery(
      ['job', shortcode],
      async () => await fetchStileJobById(shortcode)
    );
  };

  const jobPageSlug =
    knownJobIds && knownJobIds.includes(shortcode)
      ? `/who-we-are/apply/${shortcode}/`
      : `/who-we-are/apply/?job=${shortcode}`;

  const showFirstSeparator = city && (department || employment_type);
  const showSecondSeparator = department && employment_type;

  return (
    <Column space={theme.space.xxs}>
      <Link url={jobPageSlug} onMouseEnter={prefetchJob}>
        {title}
      </Link>

      <Row justify="flex-start" space={theme.space.xxxs}>
        {city && <Text size="s">{city.toUpperCase()}</Text>}

        {showFirstSeparator && <Separator />}

        {department && <Text size="s">{department.toUpperCase()}</Text>}

        {showSecondSeparator && <Separator />}

        {employment_type && <Text size="s">{employment_type.toUpperCase()}</Text>}
      </Row>

      <Divider color="grey3" />
    </Column>
  );
}
