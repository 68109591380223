import '@reach/dialog/styles.css';

import * as React from 'react';

import { DialogContent, DialogOverlay } from '@reach/dialog';
import styled, { createGlobalStyle, css } from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Icon } from '../../layout/Icon';

import { IconButton } from '../IconButton';

export type ModalProps = React.PropsWithChildren<{
  closeModal: () => void;
  isOpen: boolean;
  label: string;
  width?: React.CSSProperties['width'];
  externalCloseButton?: boolean;
  showCloseButton?: boolean;
}>;

// Blur the main site behind the modal
const GlobalStyle = createGlobalStyle`
  #___gatsby {
    filter: blur(2px);
  }
`;

export const Modal = ({
  isOpen,
  closeModal,
  label,
  width,
  externalCloseButton,
  showCloseButton = true,
  children,
}: ModalProps) => {
  const ariaLabel = `${label} Dialog`;
  return (
    <>
      {isOpen && (
        <StyledDialogOverlay $width={width} onDismiss={closeModal}>
          <GlobalStyle />
          <DialogContent aria-label={ariaLabel}>
            {children}
            {showCloseButton && (
              <CloseButton onClick={closeModal} $externalCloseButton={externalCloseButton}>
                <Icon iconId="close" iconSize="fontSize" ariaText={`Close ${ariaLabel}`} />
              </CloseButton>
            )}
          </DialogContent>
        </StyledDialogOverlay>
      )}
    </>
  );
};

export const StyledDialogOverlay = styled(DialogOverlay)<{ $width: ModalProps['width'] }>`
  z-index: 40;

  & > [data-reach-dialog-content] {
    position: relative;
    background: transparent;
    border-radius: ${theme.borderRadius.s};
    box-shadow: ${theme.shadows.bottomLight};
    padding: 0;

    ${({ $width }) => css`
      ${$width
        ? css`
            min-width: fit-content;
            max-width: ${$width};
          `
        : /* Restrict to max site width minus gutters */
          css`
            width: calc(100vw - (${theme.space.gutters} * 2));
            @media (min-width: ${theme.space.siteWidth}) {
              max-width: calc(${theme.space.siteWidth} - (${theme.space.gutters} * 2));
            }
          `};
    `}
  }
`;

export const CloseButton = styled(IconButton)<{ $externalCloseButton?: boolean }>`
  color: ${theme.colors.dark};
  font-size: 30px;
  position: absolute;
  top: 4px;
  right: 4px;

  ${({ $externalCloseButton }) =>
    $externalCloseButton &&
    css`
      top: -4px;
      right: -4px;
      transform: translateY(-100%);
    `}
`;
