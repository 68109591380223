import * as React from 'react';

import type { HeadProps, PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { HeroBlock } from 'stile-shared/src/components/2020/blocks/HeroBlock';
import { Card } from 'stile-shared/src/components/2020/common/Card';
import { Link } from 'stile-shared/src/components/2020/common/Link';
import { Section, Text } from 'stile-shared/src/components/2020/common/Primitives';
import { Grid } from 'stile-shared/src/components/2020/layout/Grid';
import { Row } from 'stile-shared/src/components/2020/layout/Row';

import { theme } from 'styles/theme';

import { PageLayout } from 'templates/2020/PageLayout';
import { SEO } from 'templates/2020/SEO';

import { CtaBlock } from 'components/blocks/CtaBlock';
import { NextPageBlock } from 'components/blocks/NextPageBlock';

export function Head(props: HeadProps) {
  return (
    <SEO
      title="Virtual reality experiences"
      description="Inspire your students’ creativity and imagination with our lessons featuring virtual reality experiences"
      slug={props.location.pathname}
    />
  );
}

function VrPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <HeroBlock
        title="Stile’s virtual reality experiences"
        text="Inspire your students’ creativity and imagination with our lessons featuring virtual reality experiences"
        image={
          <StaticImage
            loading="eager"
            aspectRatio={2.37}
            src="../../../../stile-shared/assets/images/vr/cover_vr.png"
            alt="Several students holding Stile VR headsets up to their faces"
          />
        }
      />

      <Section>
        <Grid space={theme.space.l}>
          <Card
            url="https://stileapp.com/go/vrplantcell"
            title="Parts of a cell"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_cell.png"
                alt="A view as if you were inside a plant cell, distorted like looking through a curved lense"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrplantcell">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stileapp.com/simulations/test/cosmos/vr/plant-cell/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vrnightsky"
            title="Night sky"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_night_sky.png"
                alt="The night sky, with the landscape silhouetted at the bottom"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrnightsky">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stile-simulations.s3.ap-southeast-2.amazonaws.com/v2/cosmos/vr-react/stars/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vrmars"
            title="Mars"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_mars.png"
                alt="A Mars rover on the surface of Mars"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrmars">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stile-simulations.s3.ap-southeast-2.amazonaws.com/v2/cosmos/vr-react/mars/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vrdesert"
            title="Desert"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_desert.png"
                alt="A very wide expanse of desert, with a camel visible"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrdesert">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stile-simulations.s3.ap-southeast-2.amazonaws.com/v2/cosmos/vr-react/desert/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vrtundra"
            title="Tundra"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_tundra.png"
                alt="A frozen and rocky tundra, distorted like looking through a curved lense"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrtundra">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stile-simulations.s3.ap-southeast-2.amazonaws.com/v2/cosmos/vr-react/tundra/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vrgrassland"
            title="Grassland"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_grassland.png"
                alt="A grassland with long grass in clumps, very distorted like looking through a curved lense"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrgrassland">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stile-simulations.s3.ap-southeast-2.amazonaws.com/v2/cosmos/vr-react/grassland/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vrforest"
            title="Rainforest"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_rainforest.png"
                alt="A rainforest with a path through the trees, very distorted like looking through a curved lense"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrforest">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stile-simulations.s3.ap-southeast-2.amazonaws.com/v2/cosmos/vr-react/rainforest/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vrreef"
            title="Reef"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_reef.png"
                alt="A partially bleached reef with a diver swimming over it"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrreef">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stile-simulations.s3.ap-southeast-2.amazonaws.com/v2/cosmos/vr-react/reef/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vrsafety"
            title="Lab safety"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_lab_safety.png"
                alt="A lab with a scientist in it, distorted like looking through a curved lense"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrsafety">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stile-simulations.s3.ap-southeast-2.amazonaws.com/v2/cosmos/vr-react/lab-safety/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vruniverse"
            title="Universe"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_universe.png"
                alt="A nebula that is mostly orange, with some bluish grey in the background"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vruniverse">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://s3-ap-southeast-2.amazonaws.com/stile-simulations/universe-vr.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vrearthquake"
            title="Nepal – post earthquake"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_nepal.png"
                alt="The rubble of an earthquake-damaged building, distorted like looking through a curved lense"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrearthquake">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stile-simulations.s3.ap-southeast-2.amazonaws.com/v2/cosmos/vr-react/nepal/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>

          <Card
            url="https://stileapp.com/go/vrrocks"
            title="Different rock types"
            image={
              <StaticImage
                src="../../../../stile-shared/assets/images/vr/vr_rock_types.png"
                alt="Red rock formations with sandy ground around it, distorted like looking through a curved lense"
              />
            }
          >
            <Row justify="flex-start" space={theme.space.s}>
              <Link url="https://stileapp.com/go/vrrocks">
                <Text size="s">View lesson</Text>
              </Link>
              <Link url="https://stile-simulations.s3.ap-southeast-2.amazonaws.com/v2/cosmos/vr/rocks-gallery-react/index.html">
                <Text size="s">Mobile view</Text>
              </Link>
            </Row>
          </Card>
        </Grid>
      </Section>

      <NextPageBlock path="/what-is-stile/stile-classroom/" />

      <CtaBlock />
    </PageLayout>
  );
}

export default VrPage;
