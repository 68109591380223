// WITBGridContainer/WITBGridContainer.styles.ts
import styled from 'styled-components';

export const StyledWITBGridContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    width: calc(100% + 25px);
    flex-direction: row;
    justify-content: flex-start;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    // gap: 16px;
    scroll-behavior: smooth;

    ::-webkit-scrollbar {
      display: none;
    }

    margin: 0;
    padding: 0;
  }
`;
