import * as React from 'react';

import { TRIAL_PAGE_URL } from '../../../../constants';

import { Button } from '../Button';
import { ButtonLink } from '../ButtonLink';
import { useModal } from '../Modal';
import { TrialModal } from '../TrialModal';

type TrialButtonProps = React.ComponentProps<typeof Button> & {
  usesModal?: boolean;
};

export function TrialButton({
  usesModal,
  gtagEvent,
  ...props
}: React.PropsWithChildren<TrialButtonProps>) {
  const { openModal, closeModal, isModalOpen } = useModal();

  return (
    <>
      {usesModal ? (
        <Button variant="cta" {...props} onClick={openModal} />
      ) : (
        <ButtonLink variant="cta" {...props} url={TRIAL_PAGE_URL} gtagEvent={gtagEvent} />
      )}

      {usesModal && (
        <TrialModal isOpen={isModalOpen} closeModal={closeModal} gtagEvent={gtagEvent} />
      )}
    </>
  );
}
