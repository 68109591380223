import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

/**
 * Similar to Center component, but without extra positioning.
 * Used as a quick wrapper between other layout components
 * to restrict the maximum size of children.
 * Mostly used to wrap images or content in fluid containers.
 */
export const Clamp = styled.div<{
  /** Max width for content. */
  max?: React.CSSProperties['maxWidth'];
}>`
  && {
    max-width: ${({ max }) => max ?? theme.space.measure};
  }
`;
