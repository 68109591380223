import { ensureSlashes } from './ensureSlashes';

/**
 * Replace the path prefix for gatsby urls
 * E.g. `replacePrefix('https://stileeducation.com/au/some/path', 'au', 'us');`
 * -> `https://stileeducation.com/us/some/path`
 */
export function replacePrefix(url: string, currentPrefix: string, newPrefix = '/') {
  return url.replace(
    RegExp(`^(.*)(${ensureSlashes(currentPrefix)})(.*)`),
    (m, p1, p2, p3) => `${p1}${ensureSlashes(newPrefix)}${p3}`
  );
}
