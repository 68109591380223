import * as React from 'react';

import styled, { css } from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Column } from '../../layout/Column';
import { Row } from '../../layout/Row';

import { Button } from '../Button';
import { Link } from '../Link';
import { Divider, Text } from '../Primitives';
import { LeftButton, RightButton } from './ActionButtons';
import { HeaderLogo } from './HeaderLogo';
import { useHeaderMenu } from './useHeaderMenu';

export const MobileMenuToggle = () => {
  const { state, dispatch } = useHeaderMenu();
  const isHidden = !state.mobileMenuActive;

  const toggleMobileMenu = () => {
    dispatch({
      type: 'setMobileMenuActive',
      payload: !state.mobileMenuActive,
    });
  };

  return (
    <MenuToggleButton
      onClick={toggleMobileMenu}
      variant="cta"
      aria-hidden={isHidden}
      isHidden={isHidden}
    >
      {state.mobileMenuActive ? 'Close' : 'Menu'}
    </MenuToggleButton>
  );
};

type MobileMenuProps = {
  primaryButton: React.ComponentProps<typeof LeftButton>;
  secondaryButton: React.ComponentProps<typeof RightButton>;
};

export function MobileMenu({ primaryButton, secondaryButton }: MobileMenuProps) {
  const { state, dispatch } = useHeaderMenu();
  const disableTabbingIfMenuClosed = !state.mobileMenuActive ? -1 : undefined;

  const closeMobileMenu = () => {
    dispatch({
      type: 'setMobileMenuActive',
      payload: false,
    });
  };

  return (
    <MobileMenuContainer isActive={state.mobileMenuActive} aria-hidden={!state.mobileMenuActive}>
      <Row justify="space-between">
        <HeaderLogo tabIndex={disableTabbingIfMenuClosed} />
        <MobileMenuToggle />
      </Row>

      <MobileMenuButtons>
        <LeftButton {...primaryButton} tabIndex={disableTabbingIfMenuClosed} />
        <RightButton {...secondaryButton} tabIndex={disableTabbingIfMenuClosed} />
      </MobileMenuButtons>

      <MobileMenuNav as="nav" onClick={closeMobileMenu} role="menu">
        {state.linkSections.map((item, i) => (
          <NavSection key={item.title} role="group">
            {i !== 0 && <Divider />}
            <Column space={theme.space.m}>
              <Text bold>{item.title}</Text>
              <NavSectionLinks>
                {item.links.map(({ text, url }) => (
                  <Link
                    key={url}
                    role="menuitem"
                    variant="hover"
                    url={url}
                    tabIndex={disableTabbingIfMenuClosed}
                  >
                    {text}
                  </Link>
                ))}
              </NavSectionLinks>
            </Column>
          </NavSection>
        ))}
      </MobileMenuNav>
    </MobileMenuContainer>
  );
}

export const MenuToggleButton = styled(Button)<{ isHidden: boolean }>`
  margin-left: auto;
  min-width: 0;
  margin-top: 8px;
  margin-bottom: 8px;

  @media ${theme.media.max.s} {
    display: flex;
  }

  /* If screen changes to a dimension that we would normally hide the menu button, we wait until it's closed before hiding.
  E.g. if a user rotates to landscape they may end up with a menu they cannot close due to no close buttton */
  ${({ isHidden }) => isHidden && `display: none;`}
`;

/* Always displays over page, but transparent and no pointer-events until active */
const MobileMenuContainer = styled.div<{ isActive: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${theme.colors.white};
  z-index: 30;
  overflow-y: scroll;
  overflow-x: hidden;

  padding-left: ${theme.space.gutters};
  padding-right: ${theme.space.gutters};
  padding-bottom: ${theme.space.gutters};

  transition: opacity 0.5s ease;
  opacity: 1;

  ${(props) =>
    !props.isActive &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

export const MobileMenuButtons = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-top: 8px;
  gap: 15px;

  & > * {
    flex: 1;
  }
`;

const MobileMenuNav = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
`;

const NavSection = styled.div`
  &:active {
    outline: none;
  }
`;

export const NavSectionLinks = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1.4rem;
`;
