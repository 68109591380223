import * as React from 'react';

import underline1 from 'stile-shared/static/underline-1.png';
import underline2 from 'stile-shared/static/underline-2.png';
import underline3 from 'stile-shared/static/underline-3.png';
import underline4 from 'stile-shared/static/underline-4.png';
import underline5 from 'stile-shared/static/underline-5.png';
import underline6 from 'stile-shared/static/underline-6.png';
import underline7 from 'stile-shared/static/underline-7.png';
import underline8 from 'stile-shared/static/underline-8.png';
import underline9 from 'stile-shared/static/underline-9.png';
import underline10 from 'stile-shared/static/underline-10.png';
import underline11 from 'stile-shared/static/underline-11.png';
import underline12 from 'stile-shared/static/underline-12.png';
import styled from 'styled-components';

type UnderlineProps = React.HTMLAttributes<HTMLImageElement> & {
  underlineNumber: number;
  speed?: string;
};

const UnderlineImage = styled.img<{ $width?: string; $speed?: string }>`
  &.hiddenBeforeReveal {
    /* width: 0 !important; */
  }

  &.animatedReveal {
    /* animation: animatedReveal ${(props) => props.$speed || '1s'} linear; */
  }
  width: ${(props) => props.$width || '277px'};
  height: 12px;
  border-top-right-radius: 14px;
  border-bottom-right-radius: 14px;
  /* 
  @keyframes animatedReveal {
    from {
      width: 0;
    }
    to {
      width: ${(props) => props.$width || '277px'};
    }
  } */
`;

/**
 * Displays stile Underline in different colours
 */
export const Underline = ({
  underlineNumber,
  speed,
  ...props
}: React.PropsWithChildren<UnderlineProps>) => {
  const [animateUnderline, setAnimateUnderline] = React.useState(false);

  const underline = [
    underline1,
    underline2,
    underline3,
    underline4,
    underline5,
    underline6,
    underline7,
    underline8,
    underline9,
    underline10,
    underline11,
    underline12,
  ][underlineNumber - 1];

  const maxWidth = [
    277, 277, 277, 277, 112, 112, 112, 112, 112, 112, 112, 112, 112, 112, 112, 112, 112,
  ][underlineNumber - 1];

  React.useEffect(() => {
    setTimeout(() => {
      setAnimateUnderline(true);
    }, 500);
  }, []);

  return (
    <UnderlineImage
      ref={React.createRef()}
      className={`underline ${animateUnderline ? 'animatedReveal' : 'hiddenBeforeReveal'}`}
      src={underline}
      width={maxWidth}
      $width={`${maxWidth}px`}
      $speed={speed}
      style={{ maxWidth: `${maxWidth}px` }}
      alt="underline"
      loading="eager"
      {...props}
    />
  );
};
