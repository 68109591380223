import * as React from 'react';
import { CSSProperties } from 'react';

import styled from 'styled-components';

import { LOCALE_URLS } from '../../../../constants';

import { getRegionFromLocale } from '../../../../utils/getRegionFromLocale';
import { replacePrefix } from '../../../../utils/replacePrefix';

import { Box } from '../../layout/Box';

import { Select } from '../Select';

const locales = [
  {
    text: 'AU',
    value: 'en-au',
  },
  {
    text: 'US',
    value: 'en-us',
  },
];

type SiteSelectorProps = {
  locale: Exclude<keyof typeof LOCALE_URLS, 'x-default' | 'en'>;
  style?: CSSProperties | undefined;
  selectIconStyles?: CSSProperties | undefined;
};

export function SiteSelector({ locale, style, selectIconStyles }: SiteSelectorProps) {
  const defaultValue = locales.find((option) => option.value === locale)?.value;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    // switch locale, keeping current page path
    const newPathname = replacePrefix(
      window.location.pathname,
      getRegionFromLocale(locale),
      getRegionFromLocale(e.target.value)
    );
    // redirect
    window.location.href = window.location.origin + newPathname;
  };

  return (
    <SiteSelectorContainer space={0} invertColors>
      <Select
        variant="dark"
        defaultValue={defaultValue}
        options={locales}
        onChange={handleChange}
        style={style}
        selectIconStyles={selectIconStyles}
      />
    </SiteSelectorContainer>
  );
}

const SiteSelectorContainer = styled(Box)`
  width: 82px;
  select {
    height: 42px;
  }
`;
