import styled from 'styled-components';

export const StyledWITBGridRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 28px;
  padding: 40px 0;
  &:last-child {
    padding-bottom: 0;
  }

  @media (max-width: 768px) {
    gap: 8px;
    min-width: 330px;
    flex-direction: column;
    overflow-x: auto;
    scroll-snap-align: start;
    scroll-behavior: smooth; /* Smooth scrolling */
    padding: 0;
    &:last-child {
      margin-right: 100px;
    }
  }
`;
