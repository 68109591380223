import { USER_REGION_COOKIE_NAME } from '../constants';

import { isBrowser } from './isBrowser';

// returns 'au' or 'us'
export function getUserLocaleFromCookie() {
  if (isBrowser()) {
    return window.document.cookie
      .split('; ')
      .find((row) => row.startsWith(`${USER_REGION_COOKIE_NAME}=`))
      ?.split('=')[1];
  }
}
