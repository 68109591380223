import 'flatpickr/dist/themes/airbnb.css';

import * as React from 'react';

import minMaxTimePlugin from 'flatpickr/dist/plugins/minMaxTimePlugin';
import Flatpickr, { DateTimePickerProps } from 'react-flatpickr';
import { createGlobalStyle } from 'styled-components';

import { theme } from '../../../../styles/2020/theme';
import { inputStyle } from '../../../../styles/2020/typography';

type DateTimeProps = DateTimePickerProps;

const defaultOptions = {
  // format: 18th July 2022 — 10:15AM
  dateFormat: 'J F Y  —  h:iK',
  // start week on Monday
  locale: { firstDayOfWeek: 1 },
  // disable dates in the past
  minDate: Date.now(),
  // disable saturday and sunday
  disable: [(date: Date) => date.getDay() === 0 || date.getDay() === 6],
  // restrict time range to working hours
  plugins: [minMaxTimePlugin({ getTimeLimits: () => ({ minTime: '9:00', maxTime: '16:00' }) })],
};

export const DateTime = React.forwardRef<Flatpickr, DateTimeProps>((props, ref) => (
  <>
    <FlatpickrStyles />
    <Flatpickr
      data-enable-time
      placeholder="Select a date / time"
      options={defaultOptions}
      {...props}
      ref={ref}
    />
  </>
));

DateTime.displayName = 'DateTime';

const FlatpickrStyles = createGlobalStyle`
  .flatpickr-input {
    ${inputStyle}
  }

  .flatpickr-day {
    &.selected,
    &.today.selected {
      background: ${theme.colors.brand} !important;
      border-color: ${theme.colors.brand} !important;
    }

    &.today {
      background: #ced0d6 !important;
      border: #ced0d6 !important;
    }
  }

  .flatpickr-next-month,
  .flatpickr-prev-month {
    &:hover {
      &,
      svg {
        color: ${theme.colors.brand} !important;
        fill: ${theme.colors.brand} !important;
      }
    }
  }
`;
