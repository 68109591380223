import React, { useState } from 'react';

import { STILE_APP_URL, TRIAL_PAGE_URL } from '../../../../constants';

import { theme } from '../../../../styles/2020/theme';

import { Box } from '../../layout/Box';
import { Column } from '../../layout/Column';

import { Button } from '../Button';
import { ButtonLink } from '../ButtonLink';
import { Modal } from '../Modal';
import { Heading } from '../Primitives';

type OptionType = {
  text: string;
  url?: string;
  changeOptions?: string;
};

const optionData: Record<string, OptionType[]> = {
  initial: [
    {
      text: `I'm a teacher`,
      changeOptions: 'teacher',
    },
    {
      text: `I'm a student`,
      url: STILE_APP_URL,
    },
  ],
  teacher: [
    {
      text: `My school does not use Stile yet`,
      url: TRIAL_PAGE_URL,
    },
    {
      text: `My school already uses Stile`,
      url: STILE_APP_URL,
    },
  ],
};

type TrialModalProps = Omit<React.ComponentProps<typeof Modal>, 'label'> &
  Pick<React.ComponentProps<typeof Button>, 'gtagEvent'>;

export function TrialModal({ gtagEvent, closeModal, ...props }: TrialModalProps) {
  const [options, setOptions] = useState<OptionType[]>(optionData.initial);

  const handleCloseModal = () => {
    closeModal();
    setOptions(optionData.initial);
  };

  return (
    <Modal {...props} width="500px" closeModal={handleCloseModal} label="User Type Select">
      <Box space={theme.space.xl} usePadding>
        <Column>
          <Heading textAlign="center">Tell us about you</Heading>
          <Column>
            {options.map(({ text, url, changeOptions }) => {
              if (changeOptions) {
                return (
                  <Button
                    key={text}
                    stretch
                    style={{ maxWidth: 700, boxSizing: 'border-box' }}
                    onClick={() => setOptions(optionData[changeOptions])}
                  >
                    {text}
                  </Button>
                );
              }

              if (url) {
                return (
                  <ButtonLink
                    key={text}
                    url={url}
                    {...(url === TRIAL_PAGE_URL && { gtagEvent })}
                    onClick={() => closeModal()}
                    stretch
                    style={{ maxWidth: 700, boxSizing: 'border-box' }}
                  >
                    {text}
                  </ButtonLink>
                );
              }
            })}
          </Column>
        </Column>
      </Box>
    </Modal>
  );
}
