import '@reach/combobox/styles.css';

import React, { ChangeEvent, useState } from 'react';

import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from '@reach/combobox';
import { RegisterOptions } from 'react-hook-form';

type StyledComboBoxType = {
  ariaLabel: string;
  name: string;
  placeholder?: string;
  options: string[];
  register: any; // Cannot make this type more specific due to limitations on partial forms: https://github.com/react-hook-form/react-hook-form/issues/6726
  validationOptions?: RegisterOptions;
};

export const StyledComboBox = React.forwardRef(function _StyledComboBox(
  { ariaLabel, name, placeholder, options, register, validationOptions }: StyledComboBoxType,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  console.log(typeof register);

  const [currentOptions, setCurrentOptions] = useState(options);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const userInput = e.currentTarget.value;

    const filteredOptions = options.filter((suggestion) =>
      suggestion.toLowerCase().startsWith(userInput.toLowerCase())
    );

    setCurrentOptions(filteredOptions);
  };

  return (
    <Combobox ref={ref} aria-label={ariaLabel}>
      <ComboboxInput
        placeholder={placeholder}
        {...register(name, validationOptions)}
        onChange={onChange}
      />
      <ComboboxPopover>
        <ComboboxList>
          {currentOptions.map((option) => {
            return <ComboboxOption key={option} value={option} />;
          })}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
});
