import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Link } from '../../common/Link';
import { Logo } from '../../common/Logo';

export function HeaderLogo(props: Omit<React.ComponentProps<typeof Link>, 'url'>) {
  return (
    <LogoWrapper {...props} as={Link} url="/" variant="hover" aria-label="Return home">
      <Logo width="100%" />
    </LogoWrapper>
  );
}

const LogoWrapper = styled.div`
  /* Using && otherwise styles won't override Link styles ¯\_(ツ)_/¯ */
  && {
    width: 60px;
    height: 24px;
    margin-top: 0;

    /* Increase tap/click target */
    box-sizing: content-box;
    padding: 18px 16px 16px 0;

    /* Adjust vertical alignment to match desktop nav section titles "What is Stile" */
    @media ${theme.media.min.s} {
      margin-top: -8px;
    }
  }
`;
