import styled from 'styled-components';

import WITBGridContainer from '../Included/WITBGridContainer/WITBGridContainer';

// Wrapper for the entire scroll container
export const ScrollIndicatorContainerWrapper = styled.div`
  overflow: hidden; /* Prevents page overflow */
  position: relative;
  // width: 95vw; Ensures it spans the full screen width
  /* height: 650px; */
  .end-overlay {
    display: none;
  }

  @media (max-width: 768px) {
    .end-overlay {
      display: block;
      position: absolute;
      width: 150px;
      height: 100%;
      top: 0px;
      right: 0px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.8));
    }
  }
`;

// Styled grid container to allow horizontal scrolling
export const StyledWITBGridContainer = styled(WITBGridContainer)`
  overflow-x: auto; /* Enables horizontal scrolling */
  display: flex;
  scroll-snap-type: x mandatory; /* Enables horizontal snapping */
  flex-wrap: nowrap; /* Prevents wrapping of child elements */
  scroll-behavior: smooth; /* Smooth scrolling */
  // width: 100%; /* Ensure the grid fills the container */
`;

// Wrapper for the scroll indicator to fix its position
export const ScrollIndicatorWrapper = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed; /* Pin the indicator to the bottom-left of the screen */
    bottom: 20px;
    left: 20px;
    z-index: 100; /* Ensure it stays above other elements */
    width: 112px;
    margin: 0; /* Remove margins that might shift it */
    flex-shrink: 0;
  }
`;

// Image inside the scroll indicator
export const ScrollIndicatorImage = styled.img`
  width: 50px;
  height: auto;
  margin-bottom: 10px;
`;

// Text inside the scroll indicator
export const PageText = styled.div`
  font-size: 14px;
  width: 60px;
  color: #706d69;
  text-align: right;
  transform: translateY(15px); /* Adjusts the text position */
`;
