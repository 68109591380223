// src/pages/who-we-are/our-principles/HeroSection.tsx
import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import { HowUL } from 'stile-shared/src/components/2024/common/AltUnderline/AltUnderline';

export const StyledImage = styled.div`
  border-radius: 16px;
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 60px 0 0 0;
  background-color: var(--stile-grey-1);
  @media (max-width: 768px) {
    padding: 0;
  }
`;

const Intro = styled.section`
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  text-align: center;
  padding: 0 0 130px 0;
  color: var(--stile-grey-8);
  font-family: 'Boing';
  font-size: 55px;
  font-weight: 500;
  line-height: 60px;
  word-wrap: break-word;
  @media (max-width: 768px) {
    padding: 38px 30px 60px 30px;
    font-size: 34px;
    line-height: 35px;
    img {
      width: 200px;
      height: 200px;
    }
  }
`;

const PageTitle = styled.div`
  font-family: Graphik;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: var(--stile-green-2);
  padding: 32px;
  @media (max-width: 768px) {
    padding: 20px 32px;
  }
`;

const PageBlurb = styled.h2`
  width: 696px;
  padding: 0;
  margin: 0;
  @media (max-width: 768px) {
    width: 332px;
    height: 275px;
  }
`;

const HeroSection: React.FC = () => (
  <Hero>
    <Intro>
      <StyledImage>
        <StaticImage
          src="./images/01-hero-gradcap.png"
          alt="Graduation Cap"
          placeholder="blurred"
          layout="constrained"
          width={260}
          height={260}
          imgStyle={{
            width: '100%',
            height: 'auto',
          }}
        />
      </StyledImage>
      <PageTitle>Our guarantees & principles</PageTitle>
      <PageBlurb>
        We’re science nerds that care deeply about providing the next generation with a world-class
        education.
        <br />
        <br />
        But the <HowUL>how</HowUL> matters.
      </PageBlurb>
    </Intro>
  </Hero>
);

export default HeroSection;
