// WITBFeatureSection/WITBFeatureSection.styles.ts
import styled from 'styled-components';

export const StyledWITBFeatureSection = styled.section`
  position: relative;
  margin: 0 auto;
  padding: 0;

  @media (max-width: 768px) {
    margin-bottom: 2px;
    width: 100%;
  }

  .scrollable-container {
    position: relative;
  }

  .title-container {
    display: flex;
    align-items: center;
    margin-block-start: 80px;
    margin-block-end: 0;
    justify-content: space-between; /* Distribute space between h4 and LearnMore */
    padding: 0;

    h4 {
      margin: 0;
      padding: 0;
    }

    @media (max-width: 768px) {
      flex-direction: column; /* Stack elements vertically on mobile */
      margin: 30px 0 60px 0;
      align-items: flex-start; /* Align content to the left on mobile */
      padding: 0;

      h6 {
        margin-top: 12px; /* Add spacing between h4 and the link */
      }
    }
  }

  .scroll-indicator {
    margin-bottom: -20px;
    margin-left: -165px;
    background: var(--white);
    padding: 5px;
    position: absolute;
    top: -50px;
    left: 50%;
    z-index: 501;
  }

  .overlay {
    position: absolute;
    top: 0;
    width: 25px;
    height: 100%;
    pointer-events: none;
    z-index: 2;

    &.left {
      left: 0;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.5), transparent);
    }

    &.right {
      right: 0;
      background: linear-gradient(to left, rgba(255, 255, 255, 0.5), transparent);
    }
  }
`;
