// src/components/blocks/our-principles/GuaranteeBadge.tsx
import React from 'react';

const GuaranteeBadge: React.FC = () => (
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.40567 0.00207231C4.79253 0.037741 4.21621 0.528209 4.24371 1.26816C4.27333 2.06364 3.54543 2.67451 2.76772 2.5078C1.60834 2.259 0.81112 3.64171 1.60576 4.52098C2.13975 5.11082 1.97513 6.04444 1.27188 6.4168C0.224211 6.9711 0.501415 8.54352 1.67518 8.706C2.46389 8.81517 2.93837 9.63829 2.63879 10.3754C2.48339 10.7578 2.53837 11.1269 2.70821 11.4249L0.5 15.2331H3.46191V15.2348L4.72304 18L7.37753 13.5753L10.032 18L11.2931 15.2331H14.2551L12.0617 11.4514C12.2436 11.1493 12.3045 10.7691 12.1444 10.3754C11.8448 9.63829 12.3193 8.81517 13.108 8.706C14.2809 8.54352 14.5573 6.9711 13.5096 6.4168C12.8064 6.04444 12.6417 5.11082 13.1757 4.52098C13.9704 3.64171 13.1723 2.259 12.0138 2.5078C11.2352 2.67536 10.5082 2.06364 10.5378 1.26816C10.5818 0.084239 9.08215 -0.46033 8.35436 0.474789C7.86522 1.10271 6.91543 1.10271 6.42713 0.474789C6.15422 0.124119 5.77356 -0.0193288 5.40567 0.00207231ZM9.57747 3.9871L10.7741 5.18212L6.96762 8.98864L4.39578 6.4168L5.59079 5.22013L6.96762 6.59696L9.57747 3.9871ZM4.95114 11.6861C5.43368 11.6406 5.91151 11.8932 6.1445 12.3423L4.90155 14.415C4.60239 13.8949 4.07077 13.5406 3.46191 13.5406H3.43877L4.40569 11.8745C4.41015 11.8718 4.41447 11.8707 4.41892 11.8679C4.58753 11.7621 4.76885 11.7033 4.95114 11.6861ZM9.83201 11.6861C10.0057 11.7025 10.1776 11.7598 10.3394 11.8563L11.3163 13.5406H11.2931C10.685 13.5406 10.1528 13.8938 9.8535 14.415L8.62543 12.3687C8.85298 11.903 9.34016 11.6397 9.83201 11.6861Z"
      fill="#00A541"
    />
  </svg>
);

export default GuaranteeBadge;
