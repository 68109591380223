// WITBGridContainer.tsx
import React from 'react';

import { StyledWITBGridContainer } from './WITBGridContainer.styles';

interface WITBGridContainerProps {
  children: React.ReactNode;
}

const WITBGridContainer = React.forwardRef<HTMLDivElement, WITBGridContainerProps>(
  ({ children, ...props }, ref) => (
    <StyledWITBGridContainer ref={ref} {...props}>
      {children}
    </StyledWITBGridContainer>
  )
);

WITBGridContainer.displayName = 'WITBGridContainer';

export default WITBGridContainer;
