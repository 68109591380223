import * as React from 'react';

import styled, { css } from 'styled-components';

import type {
  ColorProps,
  FontModifierProps,
  FontSizeProps,
  StyledAsProps,
  TextAlignProps,
} from '../../../../types/props';

import { theme } from '../../../../styles/2020/theme';
import { heading, lineHeight, text } from '../../../../styles/2020/typography';

import { Box } from '../../layout/Box';
import { Center } from '../../layout/Center';
import { Column } from '../../layout/Column';

type HeadingProps = FontSizeProps & TextAlignProps & ColorProps & { id?: string };

/**
 * Use headings for styling but use the as` prop to set heading level appropriately as needed
 * E.g. <H2 as="h3" />
 * See if unsure: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Heading_Elements
 */
export const Heading = styled.div<HeadingProps>`
  ${heading}
  ${({ color }) => color && `color: ${theme.colors[color]};`}
  ${({ size }) => size && `font-size: ${theme.fontSizes[size]};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}

  &:not(div) {
    max-width: ${theme.space.measure};
  }
`;

type HeadingHoCProps = React.PropsWithChildren<StyledAsProps & HeadingProps>;

// Named as such instead of H1 to remind that you should only ever have one h1 per page.
export const PageHeading = (props: HeadingHoCProps) => <Heading as="h1" size="xxl" {...props} />;
export const H2 = (props: HeadingHoCProps) => <Heading as="h2" size="xl" {...props} />;
export const H3 = (props: HeadingHoCProps) => <Heading as="h3" size="l" {...props} />;
export const H4 = (props: HeadingHoCProps) => <Heading as="h4" size="l" {...props} />;
export const H5 = (props: HeadingHoCProps) => <Heading as="h5" size="l" {...props} />;

type TextVariant = 'greenBubble';
type TextProps = ColorProps &
  FontSizeProps &
  TextAlignProps &
  FontModifierProps & { textVariant?: TextVariant };

export const Text = styled.div<TextProps>`
  ${text}
  ${({ color }) => color && `color: ${theme.colors[color]};`}
  ${({ size }) => size && `font-size: ${theme.fontSizes[size]};`}
  ${({ semiBold }) => semiBold && `font-weight: 500;`}
  ${({ bold }) => bold && `font-weight: 600;`}
  ${({ smallCaps }) =>
    smallCaps &&
    `font-variant-caps: all-small-caps; text-transform: uppercase; letter-spacing: 1px;`}
  ${({ italic }) => `font-style: ${italic ? 'italic' : 'inherit'};`}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ textVariant }) =>
    textVariant === 'greenBubble' &&
    `color: ${theme.colors.white}; background-color: ${theme.colors.green2}; width: fit-content; margin: auto; padding: 4px 24px; border-radius: ${theme.borderRadius.l};`}

  &:not(div) {
    max-width: ${theme.space.measure};
  }
`;

type TextHoCProps = React.PropsWithChildren<StyledAsProps & TextProps>;

export const P = (props: TextHoCProps) => <Text as="p" {...props} />;

export const Lead = (props: TextHoCProps) => <P size="l" {...props} />;

export const BlockQuote = styled(Text).attrs({
  as: 'blockquote',
})`
  quotes: '“' '”' '‘' '’';

  &::before {
    content: open-quote;
  }

  &::after {
    content: close-quote;
  }
`;

export const Main = styled(Center).attrs({
  forwardedAs: 'main',
  stretch: true,
})``;

export const Section = styled(Box).attrs({ forwardedAs: 'section' })``;

export const Spacer = styled.div`
  margin-top: ${theme.space.section};
`;

export const Ul = styled(Column).attrs({
  forwardedAs: 'ul',
})`
  list-style-type: disc;
  padding-left: ${theme.space.sToM};

  > li {
    ${lineHeight}
    padding-left: 0.375em;
  }
`;

export const Ol = styled(Column).attrs({
  forwardedAs: 'ol',
})`
  padding-left: ${theme.space.sToM};

  > li {
    ${lineHeight}
    padding-left: 0.375em;
  }
`;

export const Divider = styled.div<ColorProps & { space?: React.CSSProperties['marginTop'] }>(
  ({ color, space }) => css`
    margin-top: ${space ?? theme.space.mToL};
    margin-bottom: ${space ?? theme.space.mToL};
    border-top: 1px solid ${color ? theme.colors[color] : theme.colors.grey4};
  `
);

export const Separator = styled.div.attrs({ children: '·' })``;

/**
 *
 * Use to style longform text blocks using plain html elements (h1, p, a, ul, li etc) rather
 * than pre-styled styled components.
 * E.g. A slab of html from an api response, parsed markdown etc
 */
export const Prose = ({ className, ...props }: React.ComponentProps<typeof Center>) => {
  const classes = className ? `${className} prose` : 'prose';
  return <Center className={classes} max={theme.space.measure} {...props} />;
};
