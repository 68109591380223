import documentIcon0101 from 'stile-shared/static/stileicons/01-01.svg';
import natui2Icon0102 from 'stile-shared/static/stileicons/01-02.svg';
import newsIcon0103 from 'stile-shared/static/stileicons/01-03.svg';
import testpassIcon0104 from 'stile-shared/static/stileicons/01-04.svg';
import videoIcon0105 from 'stile-shared/static/stileicons/01-05.svg';
import gameIcon0106 from 'stile-shared/static/stileicons/01-06.svg';
import askQIcon0107 from 'stile-shared/static/stileicons/01-07.svg';
import emergIcon0108 from 'stile-shared/static/stileicons/01-08.svg';
import maleuserIcon0109 from 'stile-shared/static/stileicons/01-09.svg';
import testtubeIcon0201 from 'stile-shared/static/stileicons/02-01.svg';
import temperIcon0202 from 'stile-shared/static/stileicons/02-02.svg';
import batlevelIcon0203 from 'stile-shared/static/stileicons/02-03.svg';
import inscriptIcon0301 from 'stile-shared/static/stileicons/03-01.svg';
import cardsIcon0302 from 'stile-shared/static/stileicons/03-02.svg';
import testpassIcon0303 from 'stile-shared/static/stileicons/03-03.svg';
import spiralIcon0401 from 'stile-shared/static/stileicons/04-01.svg';
import timelineIcon0402 from 'stile-shared/static/stileicons/04-02.svg';
import highlightIcon0403 from 'stile-shared/static/stileicons/04-03.svg';
import lightonIcon0404 from 'stile-shared/static/stileicons/04-04.svg';
import checklistIcon0405 from 'stile-shared/static/stileicons/04-05.svg';
import errorIcon0406 from 'stile-shared/static/stileicons/04-06.svg';
import servbellIcon0407 from 'stile-shared/static/stileicons/04-07.svg';
import classroomIcon0501 from 'stile-shared/static/stileicons/05-01.svg';
import reportcardIcon0502 from 'stile-shared/static/stileicons/05-02.svg';
import vidconIcon0503 from 'stile-shared/static/stileicons/05-03.svg';
import meetingIcon0504 from 'stile-shared/static/stileicons/05-04.svg';
import conflictIcon0505 from 'stile-shared/static/stileicons/05-05.svg';
import win10Icon0506 from 'stile-shared/static/stileicons/05-06.svg';
import padlockIcon0601 from 'stile-shared/static/stileicons/06-01.svg';
import toolsIcon0602 from 'stile-shared/static/stileicons/06-02.svg';
import clouduploadIcon0603 from 'stile-shared/static/stileicons/06-03.svg';
import puzzleIcon0604 from 'stile-shared/static/stileicons/06-04.svg';
import combochartIcon0605 from 'stile-shared/static/stileicons/06-05.svg';
import favouriteIcon0606 from 'stile-shared/static/stileicons/06-06.svg';
import teamworkIcon0701 from 'stile-shared/static/stileicons/07-01.svg';
import workspaceIcon0702 from 'stile-shared/static/stileicons/07-02.svg';
import femwriteIcon0703 from 'stile-shared/static/stileicons/07-03.svg';

export const IconSet: { [key: string]: any } = {
    documentIcon0101,
    natui2Icon0102,
    newsIcon0103,
    testpassIcon0104,
    videoIcon0105,
    gameIcon0106,
    askQIcon0107,
    emergIcon0108,
    maleuserIcon0109,
    testtubeIcon0201,
    temperIcon0202,
    batlevelIcon0203,
    inscriptIcon0301,
    cardsIcon0302,
    testpassIcon0303,
    spiralIcon0401,
    timelineIcon0402,
    highlightIcon0403,
    lightonIcon0404,
    checklistIcon0405,
    errorIcon0406,
    servbellIcon0407,
    classroomIcon0501,
    reportcardIcon0502,
    vidconIcon0503,
    meetingIcon0504,
    conflictIcon0505,
    win10Icon0506,
    padlockIcon0601,
    toolsIcon0602,
    clouduploadIcon0603,
    puzzleIcon0604,
    combochartIcon0605,
    favouriteIcon0606,
    teamworkIcon0701,
    workspaceIcon0702,
    femwriteIcon0703,
};

