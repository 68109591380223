// WITBFeatureSection/WITBFeatureSection.tsx
import React from 'react';

import WITBGridContainer from '../WITBGridContainer/WITBGridContainer';
import LearnMore from './LearnMore';
import { StyledWITBFeatureSection } from './WITBFeatureSection.styles';

interface WITBFeatureSectionProps {
  id: string;
  title: string;
  learnMoreHref?: string;
  children: React.ReactNode;
}

const WITBFeatureSection: React.FC<WITBFeatureSectionProps> = ({
  id,
  title,
  learnMoreHref,
  children,
  ...props
}) => (
  <StyledWITBFeatureSection id={id}>
    <div {...props} className="title-container">
      <h4>{title}</h4>
      {learnMoreHref && <LearnMore href={learnMoreHref} />}
    </div>
    <WITBGridContainer>{children}</WITBGridContainer>
    {/* <div class="overlay left"></div>
    <div class="overlay right"></div> */}
  </StyledWITBFeatureSection>
);

export default WITBFeatureSection;
