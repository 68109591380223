import React from 'react';
import { StyledWITBTitleMenu } from './WITBTitleMenu.styles';

interface WITBTitleMenuProps {
  children: React.ReactNode;
}

const WITBTitleMenu: React.FC<WITBTitleMenuProps> = ({ children }) => (
  <StyledWITBTitleMenu>
    {children}
  </StyledWITBTitleMenu>
);

export default WITBTitleMenu;
