import styled from 'styled-components';

export const StyledWITBTilesContainer = styled.section`
  display: flex;
  max-width: 100%;
  margin: 60px auto 120px 0;
  flex-wrap: nowrap;
  gap: 12px;
  align-items: flex-start;
  padding: 0;
  justify-content: center;

  overflow-x: hidden;
  /* 
  &::-webkit-scrollbar {
    display: none;
  } */

  /* -ms-overflow-style: none; */
  scrollbar-width: auto;

  @media (max-width: 1090px) {
    overflow-x: auto;
    justify-content: start;
  }

  @media (max-width: 768px), (hover: none) and (pointer: coarse) {
    max-width: 768px;
    margin: 25px auto 40px auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    gap: 0px;
    padding: 0px;
    justify-content: left;

    & > div {
      scroll-snap-align: center;
    }
  }
`;
