// WITBFeatureSection/LearnMore.styles.ts
import styled from 'styled-components';

export const StyledLearnMore = styled.h6`
  padding: 0;
  margin: 0;
  a {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    color: var(--stile-grey-8);
    cursor: pointer;
    transition: color 0.3s ease;
    text-decoration: none;

    &:hover {
      color: var(--stile-green-3); /* Change both text and arrow to green on hover */
    }
  }

  .forward-arrow {
    transition: fill 0.3s ease; /* Ensures a smooth transition for the color */
  }

  @media (max-width: 768px) {
    a {
      color: var(--stile-grey-6);
    }
  }
`;
