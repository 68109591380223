import * as React from 'react';

import styled from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Box } from '../../layout/Box';
import { Center } from '../../layout/Center';

type FullWidthBackgroundProps = React.PropsWithChildren<
  React.ComponentProps<typeof Box> &
    Pick<React.ComponentProps<typeof Center>, 'intrinsic' | 'textAlign'>
>;

/**
 * A combination of Box and Center that allows the `sceondaryColor` to bleed the full width of the viewport.
 */
export function FullWidthBackground({
  /* Center content based on its own width? */
  intrinsic = true,
  /* Align text in content */
  textAlign = 'left',
  children,
  ...props
}: FullWidthBackgroundProps) {
  return (
    <FullWidthBackgroundContainer forwardedAs="div" {...props} usePadding>
      <Center max={theme.space.siteWidth} textAlign={textAlign} intrinsic={intrinsic}>
        {children}
      </Center>
    </FullWidthBackgroundContainer>
  );
}

const FullWidthBackgroundContainer = styled(Box)`
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
`;
