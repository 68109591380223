import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

import { theme } from '../../../../styles/2020/theme';

type BoxSpace = React.CSSProperties['padding'];

type BoxProps = React.HTMLAttributes<HTMLDivElement> &
  StyledAsProps & {
    /** Border width – uses `primaryColor` to apply an outline to content */
    borderWidth?: string;
    /** The main color (e.g. text, borders) */
    primaryColor?: string;
    /** The background color */
    secondaryColor?: string;
    /** Swap dark and light colors? */
    invertColors?: boolean;
    /** Override spacing for content, supply a single value for all sides or [x, y] */
    space?: BoxSpace | [BoxSpace, BoxSpace];
    /* Use padding for `space` values? */
    usePadding?: boolean;
  };

/**
 * Container for sectioning content that adds default spacing.
 * Sets colors (for children to inherit)
 * Can invert colors for contrasted sections.
 */
export const Box = ({
  borderWidth = '0',
  primaryColor = theme.colors.dark,
  secondaryColor = theme.colors.white,
  space = [theme.space.section, theme.space.gutters],
  children,
  ...props
}: BoxProps) => {
  return (
    <BoxContainer
      borderWidth={borderWidth}
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}
      space={space}
      {...props}
    >
      {children}
    </BoxContainer>
  );
};

const BoxContainer = styled.div<BoxProps>(
  ({ space, borderWidth, primaryColor, secondaryColor, usePadding, invertColors }) => css`
    position: relative;
    background-color: ${invertColors ? primaryColor : secondaryColor};
    color: ${invertColors ? secondaryColor : primaryColor};
    ${borderWidth !== '0' ? `border: ${borderWidth} solid;` : ''}
    ${usePadding
      ? css`
          /* Padding to ensure background is included in spacing between adjacent sections */
          padding: ${getSpace(space)};
        `
      : css`
          /* Vertical margin to allow double spacing to collapse between sections
            This assumes non full-width sections have the same background color however
            (e.g. inherited / none)
          */
          margin-top: ${getSpace(space, 'y')};
          margin-bottom: ${getSpace(space, 'y')};
          padding-left: ${getSpace(space, 'x')};
          padding-right: ${getSpace(space, 'x')};
        `}

    * {
      color: inherit;
    }
  `
);

const getSpace = (space: BoxProps['space'], axis?: 'x' | 'y') => {
  if (!axis) {
    return Array.isArray(space) ? space.join(' ') : space;
  }

  return Array.isArray(space) ? space[axis === 'y' ? 0 : 1] : space;
};
