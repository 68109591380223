import axios from 'axios';

import type { StileJobsJson } from '../../../../types/data';

// NOTE: This legacy v1 URL is used because CORS doesn't seem to work
// when the regular URL from the current API returns a 302.
const STILE_JOBS_API_URL = 'https://apply.workable.com/api/v1/widget/accounts/stileeducation';

export async function fetchStileJobs() {
  const { data } = await axios.get<StileJobsJson>(STILE_JOBS_API_URL);
  return data;
}
