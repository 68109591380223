import * as React from 'react';

import styled, { css } from 'styled-components';

import type { StyledAsProps } from '../../../../types/props';

import { theme } from '../../../../styles/2020/theme';

type RowProps = React.HTMLAttributes<HTMLDivElement> &
  StyledAsProps & {
    /* Align parent (if in a flex context) */
    alignSelf?: React.CSSProperties['alignSelf'];
    /* Align items */
    align?: React.CSSProperties['alignItems'];
    /* Justify content */
    justify?: React.CSSProperties['justifyContent'];
    /* Container padding */
    gutters?: React.CSSProperties['paddingLeft'];
    /* Gap between elements */
    space?: React.CSSProperties['gap'];
    /* Should elements wrap to next row? */
    wrap?: boolean;
  };

/**
 * Groups children in a horizontal row.
 * Defaults to center positioning and wraps if overflowing container.
 */
export const Row = ({
  alignSelf = 'auto',
  justify = 'center',
  align = 'center',
  space = theme.space.s,
  gutters = '0',
  wrap = true,
  children,
  ...props
}: RowProps) => {
  return (
    <RowContainer
      align={align}
      alignSelf={alignSelf}
      justify={justify}
      space={space}
      gutters={gutters}
      $wrap={wrap}
      {...props}
    >
      {children}
    </RowContainer>
  );
};

const RowContainer = styled.div<Omit<RowProps, 'wrap'> & { $wrap: RowProps['wrap'] }>(
  ({ alignSelf, align, justify, space, gutters, $wrap }) => css`
    display: flex;
    flex-wrap: ${$wrap ? 'wrap' : 'nowrap'};
    gap: ${space};

    align-items: ${align};
    align-self: ${alignSelf};
    justify-content: ${justify};

    padding-left: ${gutters};
    padding-right: ${gutters};
  `
);
