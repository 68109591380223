import * as React from 'react';

import type { HeadProps } from 'gatsby';

import { SEO } from 'templates/2024/SEO';

/**
 * Shortcut component for main site pages that have data set in app-constants
 * One-off and non-sectioned pages can declare and export their own <Head /> component.
 */
export function Head(props: HeadProps) {
  return <SEO slug={props.location.pathname} />;
}
