import React from 'react';

import { StyledWITBTile } from './WITBTile.styles';

interface WITBTileProps {
  title: string;
  cardDetail: string[];
  headerImage: JSX.Element;
  linkId: string;
}

const WITBTile: React.FC<WITBTileProps> = ({ title, cardDetail, headerImage, linkId }) => {
  const handleClick = () => {
    const element = document.getElementById(linkId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <StyledWITBTile onClick={handleClick}>
      <div className="image-container">{headerImage}</div>
      <div className="tile-content">
        <p className="tile-title">{title}</p>
        <p>{cardDetail[0]}</p>
      </div>
    </StyledWITBTile>
  );
};

export default WITBTile;
