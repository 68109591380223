import * as React from 'react';
import { CSSProperties } from 'react';

import styled, { css } from 'styled-components';

import { theme } from '../../../../styles/2020/theme';

import { Icon } from '../../layout/Icon';

export type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  options: { value: string; text: string }[];
  variant?: 'light' | 'dark';
  defaultValue?: string;
  selectIconStyles?: CSSProperties | undefined;
};

export const Select = React.forwardRef(function _Select(
  { options, variant = 'light', defaultValue, selectIconStyles, ...props }: SelectProps,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  return (
    <Container variant={variant}>
      <select {...props} ref={ref} value={defaultValue}>
        {options.map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </select>

      <SelectIcon style={selectIconStyles}>
        <Icon iconId="select-arrow" />
      </SelectIcon>
    </Container>
  );
});

// TODO: Consider replacing these with @reach/select for proper a11y
const SelectIcon = styled.div`
  pointer-events: none;
`;

const variantStyles = {
  light: css`
    select {
      border: 1px solid ${theme.colors.grey3};
      background: ${theme.colors.white};

      &::placeholder {
        color: ${theme.colors.dark};
        opacity: 0.5;
      }
    }

    & ${SelectIcon} {
      color: ${theme.colors.grey6};
      background: linear-gradient(to right, transparent, ${theme.colors.white} 25%);
    }
  `,
  dark: css`
    select {
      border: 1px solid ${theme.colors.grey6};
      background: ${theme.colors.dark};

      &::placeholder {
        color: ${theme.colors.white};
        opacity: 0.5;
      }
    }

    & ${SelectIcon} {
      color: ${theme.colors.grey4};
      background: linear-gradient(to right, transparent, ${theme.colors.dark} 25%);
    }
  `,
};

const Container = styled.div<Pick<SelectProps, 'variant'>>`
  position: relative;
  display: flex;
  width: 100%;
  max-width: 100%;
  font-size: ${theme.fontSizes.s};

  select {
    appearance: none;
    -webkit-appearance: none;
    box-shadow: none;
    display: flex;
    flex: 1;
    height: 48px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 ${theme.space.xs};
    border-radius: ${theme.borderRadius.s};
    cursor: pointer;

    transition: all ${theme.transitions.short};

    &.active,
    &:focus {
      outline: none;
      border: 1px solid ${theme.colors.green2};
      box-shadow: 0px 0px 0px 1px ${theme.colors.green2};
    }
  }

  & ${SelectIcon} {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    /* Leave space for select border */
    right: 1px;
    padding: ${theme.space.xxs} ${theme.space.xs};
  }

  ${({ variant }) => !!variant && variantStyles[variant]}
`;
