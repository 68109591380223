import React from 'react';

import { getRegionFromLocale } from '../../../../utils/getRegionFromLocale';
import { getUserLocaleFromCookie } from '../../../../utils/getUserLocaleFromCookie';
import { isBrowser } from '../../../../utils/isBrowser';
import { replacePrefix } from '../../../../utils/replacePrefix';

import { theme } from '../../../../styles/2020/theme';

import { Box } from '../../layout/Box';
import { Clamp } from '../../layout/Clamp';
import { Column } from '../../layout/Column';

import { Button } from '../Button';
import { Modal, useModal } from '../Modal';
import { P } from '../Primitives';

type SiteSwitchModalProps = Omit<
  React.ComponentProps<typeof Modal>,
  'isOpen' | 'label' | 'closeModal'
> & {
  locale: string;
};

const MODAL_WIDTH = '320px';

export function SiteSwitchModal({ locale, ...props }: SiteSwitchModalProps) {
  const { openModal, closeModal, isModalOpen } = useModal();
  const siteRegion = getRegionFromLocale(locale);
  const userLocale = getUserLocaleFromCookie();
  const isUserFromDifferentRegion = !!userLocale && userLocale !== siteRegion;
  const shouldShowModal =
    isBrowser() &&
    !isModalOpen &&
    isUserFromDifferentRegion &&
    sessionStorage.getItem('siteSwitchSeen') !== 'true';

  const handleCloseModal = () => {
    closeModal();
    sessionStorage.setItem('siteSwitchSeen', 'true');
  };

  const handleRedirect = () => {
    const newPathname = replacePrefix(window.location.pathname, siteRegion, userLocale);
    window.location.href = window.location.origin + newPathname;
    handleCloseModal();
  };

  if (shouldShowModal) {
    openModal();
  }

  return (
    <Modal
      {...props}
      isOpen={isModalOpen}
      label="Switch site region"
      width={MODAL_WIDTH}
      closeModal={handleCloseModal}
      showCloseButton={false}
    >
      <Box space={theme.space.l} usePadding>
        <Clamp max={MODAL_WIDTH}>
          <Column space={theme.space.l}>
            <P as="h2" textAlign="center" size="xl">
              It looks like you’re in {userLocale === 'au' ? 'Australia' : 'the U.S.'}
            </P>
            <Column>
              <Button stretch onClick={handleRedirect}>
                Continue to {userLocale === 'au' ? 'AU site' : 'U.S. site'}
              </Button>
              <Button variant="secondary" stretch onClick={handleCloseModal}>
                Dismiss
              </Button>
            </Column>
          </Column>
        </Clamp>
      </Box>
    </Modal>
  );
}
