import * as React from 'react';

import styled, { css } from 'styled-components';

import { useRunOnScroll } from '../../../../hooks/useRunOnScroll';

import { theme } from '../../../../styles/2020/theme';

import { Link } from '../../common/Link';

import { HeaderLinkSection, HeaderMenuState, useHeaderMenu } from './useHeaderMenu';

type SetActiveMenuDropdown = (primitiveValue: HeaderMenuState['activeMenuDropdown']) => void;

export function DesktopMenu() {
  const { state, dispatch } = useHeaderMenu();

  const setActive = React.useCallback<SetActiveMenuDropdown>(
    (primitiveValue) =>
      dispatch({
        type: 'setActiveMenuDropdown',
        payload: primitiveValue,
      }),
    [dispatch]
  );

  const hideMenu = React.useCallback(() => setActive(null), [setActive]);

  useRunOnScroll({
    isActive: state.activeMenuDropdown !== null,
    callback: hideMenu,
  });

  return (
    <NavigationItems role="menu">
      {state.linkSections.map((item, i) => (
        <NavigationItem
          key={`${item.title}-${i}`}
          item={item}
          isActive={state.activeMenuDropdown === i}
          setActive={setActive}
          hideMenu={hideMenu}
          itemIndex={i}
        />
      ))}
    </NavigationItems>
  );
}

type NavigationItemProps = {
  isActive: boolean;
  setActive: SetActiveMenuDropdown;
  hideMenu: () => void;
  item: HeaderLinkSection;
  itemIndex: number;
};

export function NavigationItem({
  item,
  itemIndex,
  isActive,
  setActive,
  hideMenu,
}: NavigationItemProps) {
  const setActiveMenu = React.useCallback(() => setActive(itemIndex), [setActive, itemIndex]);
  const menuDropdownRef = React.useRef<HTMLDivElement>(null);
  const leftOffset = menuDropdownRef?.current?.getBoundingClientRect().x;

  return (
    <NavSection active={isActive} onMouseEnter={setActiveMenu} onMouseLeave={hideMenu}>
      {item.title}
      <MenuDropdown ref={menuDropdownRef} active={isActive} leftOffset={leftOffset} role="group">
        {item.links.map(({ text, url }) => (
          <NavSectionItem key={url} as={Link} variant="hover" url={url} role="menuitem">
            {text}
          </NavSectionItem>
        ))}
      </MenuDropdown>
    </NavSection>
  );
}

const NavSection = styled.div<{ active: boolean }>`
  position: relative;
  font-size: ${theme.fontSizes.sm};
  font-weight: 500;
  height: 100%;
  // Don't use fluid sizing here
  padding: 24px 10px;

  user-select: none;
  cursor: pointer;

  transition: color 0.25s ease;

  ${({ active }) =>
    active &&
    css`
      color: ${theme.colors.green2};
    `}
`;

// Not a Row since we want padding to cover the gaps between items
// ensuring the dropdown remains active with mouse movement between sections
const NavigationItems = styled.div`
  display: none;

  @media ${theme.media.min.s} {
    display: flex;
  }
`;

const NavSectionItem = styled.div`
  && {
    z-index: 2;
    user-select: none;
    color: ${theme.colors.dark};
    padding-top: ${theme.space.xxs};
    padding-bottom: ${theme.space.xxs};
  }
`;

const MenuDropdown = styled.div<{ active: boolean; leftOffset?: number }>`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-top: ${theme.space.xxs};
  padding-bottom: ${theme.space.xxs};
  font-weight: 400;

  background: ${theme.colors.white};
  width: 600px;

  opacity: 0;
  pointer-events: none;

  // Show if user is tabbing through site
  &:focus-within {
    opacity: 1;
  }

  ${({ active }) =>
    active &&
    css`
      pointer-events: all;
      opacity: 1;
    `}

  // Full width background / panel
  &::before {
    content: '';
    position: absolute;
    left: -${({ leftOffset }) => leftOffset || 0}px;
    bottom: 0;
    top: 24px;
    width: 100vw;
    background: ${theme.colors.white};
    z-index: 2;
  }
`;
