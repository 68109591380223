import React from 'react';
import { StyledWITBStatsGrid } from './WITBStatsGrid.styles';
import WITBStatItem from '../WITBStatItem/WITBStatItem';

interface WITBStatsGridProps {
  stats: { value: string; label: string }[];
}

const WITBStatsGrid: React.FC<WITBStatsGridProps> = ({ stats }) => (
  <StyledWITBStatsGrid>
    {stats.map((stat, index) => (
      <WITBStatItem key={index} value={stat.value} label={stat.label} />
    ))}
  </StyledWITBStatsGrid>
);

export default WITBStatsGrid;
