import React from 'react';
import { StyledWITBTilesContainer } from './WITBTilesContainer.styles';

interface WITBTilesContainerProps {
  children: React.ReactNode;
}

const WITBTilesContainer: React.FC<WITBTilesContainerProps> = ({ children }) => (
  <StyledWITBTilesContainer>
    {children}
  </StyledWITBTilesContainer>
);

export default WITBTilesContainer;
