import styled, { css } from 'styled-components';

const generateWidthStyles = () => {
  let styles = '';
  for (let i = 0; i <= 100; i += 1) {
    styles += `
      &.--active-${i}::after {
        width: ${i}%;
      }
    `;
  }
  return css`
    ${styles}
  `;
};

export const StyledTriSlider = styled.div<{ $animationLength?: string }>`
  display: flex;
  padding: 30px 60px 60px 60px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 32px;
  background-color: var(--white);
  color: var(--stile-grey-8);
  box-shadow: 0px 660px 185px 0px rgba(0, 0, 0, 0), 0px 422px 169px 0px rgba(0, 0, 0, 0.01),
    0px 238px 143px 0px rgba(0, 0, 0, 0.04), 0px 106px 106px 0px rgba(0, 0, 0, 0.07),
    0px 26px 58px 0px rgba(0, 0, 0, 0.08);
  z-index: 4;

  @media (max-width: 768px) {
    border-radius: 20px;
    padding: 25px 25px 10px 25px;
    gap: 0;
  }

  .--header {
    width: calc(100% - 24px);
    padding: 0;
    margin: 0;
    display: flex;
    column-gap: 24px;
    cursor: pointer;

    .--headerButton {
      flex: 1 0 24%;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .--headerButton {
    padding-top: 17px;
    background-color: transparent;
    border: none;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
    color: var(--stile-grey-6);
    @media (max-width: 768px) {
      color: var(--stile-grey-7);
    }
    position: relative;
    display: flex;
    gap: 0px;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 2px;
      display: block;
      width: 100%;
      height: 3px;
      border-radius: 2px;
      background-color: var(--stile-grey-3);
    }
    &.--active {
      color: var(--stile-green-3);
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0px;
        display: block;
        width: 0%;
        height: 7px;
        border-radius: 3px;
        background-color: var(--stile-green-2);
        transition-property: width;
        transition-timing-function: linear;
        transition-duration: ${(props) => props.$animationLength || '1s'};
      }

      ${generateWidthStyles()}

      @media (max-width: 768px) {
      }
    }

    @media (max-width: 768px) {
      width: 100%;
      padding: 16px 0 16px 0;
    }

    h6 {
      padding: 0px 0 0 0;
      margin: 0;
    }
  }

  .--content-container {
    width: 100%;
    height: 0;
    overflow: hidden;
    &:has(.--content.--active) {
      height: auto;
    }

    @media (max-width: 768px) {
      height: auto;
      .--content {
        overflow: hidden;
      }
    }

    .--headerButton {
      display: none;
      svg {
        display: inline-flex;
      }
      @media (max-width: 768px) {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        justify-items: center;
      }
    }
    .--content {
      padding-top: 32px;
      justify-content: flex-start;
      opacity: 0;
      height: 0px;
      transition: opacity 1s;
      z-index: 1;
      display: flex;
      position: relative;
      flex-direction: column;

      @media (max-width: 768px) {
        padding-top: 0px;
      }
      &.--active {
        height: auto;
        opacity: 1;
        @media (max-width: 768px) {
          flex-direction: column-reverse;
        }
      }
      .--text {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        h4 {
          margin: 0 0 28px 0;
          padding: 0;
          @media (max-width: 768px) {
            margin: 20px 0 12px 0;
          }
        }

        p {
          margin: 0 0 40px 0;
          padding: 0;
          @media (max-width: 768px) {
            margin: 0;
          }
        }

        ul {
          margin: 0;
          @media (max-width: 768px) {
            margin-bottom: 20px;
          }
          li {
            padding-bottom: 10px;
          }
        }

        @media (max-width: 768px) {
          width: 100%;
          padding-bottom: 22px;
        }
      }
    }

    .--video,
    .--media {
      width: 100%;
      border-radius: 32px;
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
      overflow: hidden;
      position: relative;
      z-index: 0;
      border: 2px solid var(--stile-grey-1);

      @media (max-width: 768px) {
        margin-bottom: 20px;
        width: 290px;
        width: 100%;
        height: 163px;
        border-radius: 8px;
        border: 1px solid var(--stile-grey-1);
        gap: 0px;
      }
    }
  }
`;
