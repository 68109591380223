import * as React from 'react';

import logoImage from './logo_stile.svg';

type LogoProps = {
  width: string;
};

export function Logo({ width = '60px' }: LogoProps) {
  return <img width={width} src={logoImage} alt="Stile Education logo" />;
}
