import styled from 'styled-components';

export const StyledWITBTitleMenu = styled.div`
  max-width: 100%;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  text-align: center; /* Center-align text */

  @media (max-width: 768px) {
    padding: 0;
  }
`;
