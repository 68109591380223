import gtag from 'ga-gtag';

import type { StileGtagEventName } from '../types/services';

export type GtagEventParams = Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams | undefined;

export const tagEvent = (eventName: StileGtagEventName, eventParams?: GtagEventParams) => {
  const isNotProductionDomain =
    window && /localhost|testing|staging/.test(window.location.hostname);

  if (isNotProductionDomain) {
    return;
  }

  gtag('event', eventName, eventParams);
};
