import * as React from 'react';

import type { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';

import iconCircleTick from 'stile-shared/assets/icons/icon_circle_tick.svg';

import { Button } from 'stile-shared/src/components/2024/common/Button';
import { Underline } from 'stile-shared/src/components/2024/common/Underline';

import { PageLayout } from 'templates/2024/PageLayout';

import AlignedSection from 'components/blocks/states/AlignedSection';
import ExpertAssistance from 'components/blocks/states/ExpertAssistance';
import RealWorldSection from 'components/blocks/states/RealWorldSection';
import ScienceTestSection from 'components/blocks/states/ScienceTestSection';
import { TopSection } from 'components/blocks/states/TopSection';

export { Head } from 'templates/2024/Head';

const NorthCarolinaTopSection = styled(TopSection)`
  .content {
    padding-bottom: 145px;

    @media (max-width: 768px) {
      padding-bottom: 0;
    }
    .--right {
      width: 50%;
      .gatsby-image-wrapper {
        border-radius: 32px;
        aspect-ratio: 1;
      }

      @media (max-width: 768px) {
        left: 0;
        right: 0;
        height: 390px;
        width: calc(100% + 50px);

        position: relative;
        margin-left: -30px;
        margin-right: -90px;
        margin-top: 35px;

        .gatsby-image-wrapper {
          height: 390px;
          width: calc(100% + 20px);
          border-radius: 0;
          margin-top: 0;
          aspect-ratio: default;
        }
      }
    }

    .underline {
      margin-top: 0px;
      margin-bottom: 10px;
    }
  }
`;

const NorthCarolinaScienceTestSection = styled<typeof ScienceTestSection>(ScienceTestSection)`
  .--side-by-side {
    .--left,
    .--right {
      .--rounded-heading {
        // position it top center but half way up itself
        top: 8% !important;
        left: 40% !important;
        transform: translate(-30%, -150%) !important;
      }
    }

    .--left {
      .--rounded-heading {
        @media (max-width: 768px) {
          left: 30% !important;
          transform: translate(-20%, -150%) !important;
        }
      }
    }
  }
`;

function TwentyTwentyFourOregonPage(props: PageProps) {
  return (
    <PageLayout {...props}>
      <NorthCarolinaTopSection>
        <div className="content">
          <div className="--left">
            <h1>North Carolina, welcome to Stile</h1>
            <h6 className="h7">
              The changes to the North Carolina Science Standards are big, bold, and very… NC.
              <br />
              Stile&apos;s got you.
            </h6>
            <Underline underlineNumber={12} />
            <ul>
              <li>
                <img src={iconCircleTick} alt="bullet point" />{' '}
                <h6 className="h7">Built for the new NC standards.</h6>
              </li>
              <li>
                <img src={iconCircleTick} alt="bullet point" />{' '}
                <h6 className="h7">
                  Recommended for approval by the NCDPI for 6th, 7th, and 8th grades.
                </h6>
              </li>
            </ul>
          </div>
          <div className="--right">
            <StaticImage
              src="../../../../stile-shared/assets/images/states/north-carolina/top-image.jpg"
              alt="North Carolina Science Standards"
              placeholder="none"
              loading="eager"
            />
          </div>
        </div>
      </NorthCarolinaTopSection>

      <AlignedSection
        heading="Beautifully built for the North Carolina Science Standards"
        body={
          <>
            There&apos;s &apos;alignment.&apos; And then there&apos;s actual alignment. Thanks to
            Stile&apos;s powerful and customizable platform, we took the very best of our existing
            middle school science curriculum and blended it with brand-new lessons tailored for
            North Carolina. The result is a seamless, structured, and sequenced Middle School
            Science Curriculum for the state.
            <br />
            <br />
            It&apos;s no wonder that Stile was{' '}
            <strong>recommended for approval by the NCDPI for 6th, 7th, and 8th grades.</strong>
          </>
        }
        image={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/north-carolina/standards.jpg"
            alt="image of north carolina"
          />
        }
        buttons={[
          <Button
            key={0}
            href="/states/north-carolina-alignment"
            size="small"
            label="View alignment to North Carolina’s standards"
            className="--alignment"
          />,
        ]}
      />

      <RealWorldSection
        heading="Real-world phenomena from North Carolina’s backyard"
        subheading="Many of Stile's phenomena are drawn from wonders all around the world. After all,
          it's important that students grow into global citizens. But sometimes, there's
          nothing more fascinating than investigating phenomena right where you live. Stile's
          curriculum for North Carolina offers a balanced blend of local and global phenomena."
        tabs={[
          'Lake Norman algae',
          'Venus flytrap investigation',
          'North Carolina food web',
          'Charlotte gold rush',
        ]}
        headings={[
          'The Lake Norman algae mystery',
          'The wonders of the Venus flytrap',
          'Tracing energy through local food webs',
          'Unearthing chemistry in North Carolina’s first gold rush',
        ]}
        paragraphs={[
          'In our Water Safety unit, students dive into the mystery of blue-green algae blooms in Lake Norman, Charlotte, analyzing water quality reports to find out if the lake is safe for swimming (or, you know, playing Marco Polo). Talk about making a splash in science!',
          'Students explore the Venus flytrap, North Carolina’s very own carnivorous marvel, in our Plant Adaptations unit. They get hands-on to uncover what makes this rare plant snap shut and how it captures its prey. It’s a bite of science they won’t forget!',
          'Local ecosystems come to life in our Food Webs unit. Students build models that trace the flow of energy through North Carolina’s food chains, discovering how the intricate balance of nature is maintained in their own very backyard.',
          'The Periodic Table unit takes students back to Charlotte’s historic gold rush, where they unearth the chemistry of elements and compounds. They dig deep (get it?) to discover how the gold hunt shaped the city’s history while putting their chemistry skills to the test.',
        ]}
        images={[
          <StaticImage
            key={0}
            src="../../../../stile-shared/assets/images/states/north-carolina/slider-1.png"
            alt="feedback"
          />,
          <StaticImage
            key={1}
            src="../../../../stile-shared/assets/images/states/north-carolina/slider-2.png"
            alt="feedback"
          />,
          <StaticImage
            key={2}
            src="../../../../stile-shared/assets/images/states/north-carolina/slider-3.png"
            alt="feedback"
          />,
          <StaticImage
            key={3}
            src="../../../../stile-shared/assets/images/states/north-carolina/slider-4.png"
            alt="feedback"
          />,
        ]}
      />

      <NorthCarolinaScienceTestSection
        heading="Prepare your students for the North Carolina Science Test"
        subHeading="The North Carolina End-of-Grade science test aims to measure students' science knowledge
            and critical thinking ability. Stile’s curriculum precisely scaffolds both of these,
            providing students with plenty of opportunities to practice and excel on the North
            Carolina Science Test."
        leftHeading="North Carolina Science Test"
        leftContent={
          <>
            Which statement is true about a chemical reaction?
            <ol>
              <li>The physical states of the atoms in the reactants determine the products.</li>
              <li>Some of the atoms in the reactants will be part of the products.</li>
              <li>The number of atoms in the reactants determines the products.</li>
              <li>All of the atoms in the reactants will be a part of the products.</li>
            </ol>
          </>
        }
        rightHeading="Stile Science Test"
        rightContent={
          <>
            A chemical reaction can be used to turn water into two gases: hydrogen and oxygen.
            Determine which of the following statements about this reaction are true.
            <ol>
              <li>Hydrogen is a reactant</li>
              <li>Water is a reactant</li>
              <li>A compound is converted into two elements</li>
              <li>An element is converted into two compounds</li>
            </ol>
          </>
        }
      />

      <ExpertAssistance
        heading="Pilot Stile with Charlotte’s own expert, Mark Picardo"
        copy=" Stile is all about empowering teachers with the best support. In the Carolinas,
                  that means teaming up with Mark Picardo, a seasoned educator with extensive
                  experience in teaching, administration, and instructional coaching. Together,
                  teachers are supported (and lessons are supercharged!)."
        href="/why-choose-stile/oregon-pilot"
        expertImage={
          <StaticImage
            src="../../../../stile-shared/assets/images/states/north-carolina/expert.jpg"
            alt="Mark Picardo"
            quality={100}
            height={1250}
          />
        }
      />
    </PageLayout>
  );
}

export default TwentyTwentyFourOregonPage;
