/**
 * Ensure a path has leading and trailing slashes
 * */
export function ensureSlashes(path: string) {
  if (path[0] !== '/') {
    path = '/' + path;
  }

  if (path.length > 1 && path[path.length - 1] !== '/') {
    path = path + '/';
  }

  return path;
}
