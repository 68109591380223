// WITBFeatureSection/LearnMore.tsx
import React from 'react';

import { Link } from '../../Link';
import ForwardArrow from './ForwardArrow';
import { StyledLearnMore } from './LearnMore.styles';

interface LearnMoreProps {
  href?: string;
}

const LearnMore: React.FC<LearnMoreProps> = ({ href = '#' }) => (
  <StyledLearnMore className="h8">
    <Link url={href}>
      Learn more
      <ForwardArrow className="forward-arrow" />
    </Link>
  </StyledLearnMore>
);

export default LearnMore;
